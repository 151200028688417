/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkDetailsContainer_comment = {
    readonly id: string;
    readonly story: {
        readonly id: string;
        readonly url: string;
    };
    readonly " $refType": "LinkDetailsContainer_comment";
};
export type LinkDetailsContainer_comment$data = LinkDetailsContainer_comment;
export type LinkDetailsContainer_comment$key = {
    readonly " $data"?: LinkDetailsContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"LinkDetailsContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "LinkDetailsContainer_comment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Story",
                "kind": "LinkedField",
                "name": "story",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Comment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '9cfba88bbcffc38209e67a3e7f679521';
export default node;
