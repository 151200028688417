/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalModerationPhaseStatus_phase = {
    readonly id: string;
    readonly enabled: boolean;
    readonly signingSecret: {
        readonly secret: string;
        readonly createdAt: string;
    };
    readonly " $refType": "ExternalModerationPhaseStatus_phase";
};
export type ExternalModerationPhaseStatus_phase$data = ExternalModerationPhaseStatus_phase;
export type ExternalModerationPhaseStatus_phase$key = {
    readonly " $data"?: ExternalModerationPhaseStatus_phase$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalModerationPhaseStatus_phase">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalModerationPhaseStatus_phase",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "SigningSecret",
            "kind": "LinkedField",
            "name": "signingSecret",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secret",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "ExternalModerationPhase",
    "abstractKey": null
} as any);
(node as any).hash = '6dbe53e8e7d9318ee6252bd70e59fe55';
export default node;
