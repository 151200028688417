/* tslint:disable */
/* eslint-disable */
/* @relayHash f6d6da06a3ec9be00d0d85a243491f42 */

import { ConcreteRequest } from "relay-runtime";
export type USER_STATUS = "ACTIVE" | "BANNED" | "PREMOD" | "SUSPENDED" | "WARNED" | "%future added value";
export type PremodUserInput = {
    userID: string;
    clientMutationId: string;
};
export type PremodUserMutationVariables = {
    input: PremodUserInput;
};
export type PremodUserMutationResponse = {
    readonly premodUser: {
        readonly user: {
            readonly id: string;
            readonly status: {
                readonly current: ReadonlyArray<USER_STATUS>;
                readonly premod: {
                    readonly active: boolean;
                    readonly history: ReadonlyArray<{
                        readonly active: boolean;
                        readonly createdAt: string;
                        readonly createdBy: {
                            readonly id: string;
                            readonly username: string | null;
                        } | null;
                    }>;
                };
            };
        };
        readonly clientMutationId: string;
    };
};
export type PremodUserMutation = {
    readonly response: PremodUserMutationResponse;
    readonly variables: PremodUserMutationVariables;
};



/*
mutation PremodUserMutation(
  $input: PremodUserInput!
) {
  premodUser(input: $input) {
    user {
      id
      status {
        current
        premod {
          active
          history {
            active
            createdAt
            createdBy {
              id
              username
            }
          }
        }
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v3 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "PremodUserPayload",
            "kind": "LinkedField",
            "name": "premodUser",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "current",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PremodStatus",
                                    "kind": "LinkedField",
                                    "name": "premod",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PremodStatusHistory",
                                            "kind": "LinkedField",
                                            "name": "history",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "createdAt",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "createdBy",
                                                    "plural": false,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "username",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "PremodUserMutation",
            "selections": (v3 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "PremodUserMutation",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "id": "f6d6da06a3ec9be00d0d85a243491f42",
            "metadata": {},
            "name": "PremodUserMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'e1939ccb53538aa5129d37c815178352';
export default node;
