/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EndpointStatus_webhookEndpoint = {
    readonly id: string;
    readonly enabled: boolean;
    readonly signingSecret: {
        readonly secret: string;
        readonly createdAt: string;
    };
    readonly " $refType": "EndpointStatus_webhookEndpoint";
};
export type EndpointStatus_webhookEndpoint$data = EndpointStatus_webhookEndpoint;
export type EndpointStatus_webhookEndpoint$key = {
    readonly " $data"?: EndpointStatus_webhookEndpoint$data;
    readonly " $fragmentRefs": FragmentRefs<"EndpointStatus_webhookEndpoint">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EndpointStatus_webhookEndpoint",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "SigningSecret",
            "kind": "LinkedField",
            "name": "signingSecret",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secret",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "WebhookEndpoint",
    "abstractKey": null
} as any);
(node as any).hash = '457ea247e21478eadcf4b322b46978ce';
export default node;
