/* tslint:disable */
/* eslint-disable */
/* @relayHash 1f2527a152ad8ed5acb934f7c188aeae */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WebhookEndpointsConfigRouteQueryVariables = {};
export type WebhookEndpointsConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"WebhookEndpointsConfigContainer_settings">;
    };
};
export type WebhookEndpointsConfigRouteQuery = {
    readonly response: WebhookEndpointsConfigRouteQueryResponse;
    readonly variables: WebhookEndpointsConfigRouteQueryVariables;
};



/*
query WebhookEndpointsConfigRouteQuery {
  settings {
    ...WebhookEndpointsConfigContainer_settings
    id
  }
}

fragment WebhookEndpointRow_webhookEndpoint on WebhookEndpoint {
  id
  enabled
  url
}

fragment WebhookEndpointsConfigContainer_settings on Settings {
  webhooks {
    endpoints {
      ...WebhookEndpointRow_webhookEndpoint
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "WebhookEndpointsConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "WebhookEndpointsConfigContainer_settings"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "WebhookEndpointsConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "WebhookConfiguration",
                            "kind": "LinkedField",
                            "name": "webhooks",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WebhookEndpoint",
                                    "kind": "LinkedField",
                                    "name": "endpoints",
                                    "plural": true,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "enabled",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "url",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "1f2527a152ad8ed5acb934f7c188aeae",
            "metadata": {},
            "name": "WebhookEndpointsConfigRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '61a3b2d638d96401da99d1bbb76a3d9c';
export default node;
