/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type ConversationModalCommentContainer_comment = {
    readonly id: string;
    readonly body: string | null;
    readonly createdAt: string;
    readonly author: {
        readonly username: string | null;
        readonly id: string;
    } | null;
    readonly revision: {
        readonly id: string;
    } | null;
    readonly status: COMMENT_STATUS;
    readonly replyCount: number;
    readonly parent: {
        readonly author: {
            readonly username: string | null;
            readonly id: string;
        } | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"MediaContainer_comment">;
    readonly " $refType": "ConversationModalCommentContainer_comment";
};
export type ConversationModalCommentContainer_comment$data = ConversationModalCommentContainer_comment;
export type ConversationModalCommentContainer_comment$key = {
    readonly " $data"?: ConversationModalCommentContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"ConversationModalCommentContainer_comment">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            (v0 /*: any*/)
        ],
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ConversationModalCommentContainer_comment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
            },
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentRevision",
                "kind": "LinkedField",
                "name": "revision",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "replyCount",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": [
                    (v1 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MediaContainer_comment"
            }
        ],
        "type": "Comment",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '62aa88b76071c8dfdbd9574c4d3896f2';
export default node;
