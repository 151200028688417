/* tslint:disable */
/* eslint-disable */
/* @relayHash 0244a685696d16397147b08222791792 */

import { ConcreteRequest } from "relay-runtime";
export type UpdateSiteInput = {
    clientMutationId: string;
    id: string;
    site: UpdateSite;
};
export type UpdateSite = {
    name?: string | null;
    url?: string | null;
    contactEmail?: string | null;
    allowedOrigins?: Array<string> | null;
};
export type UpdateSiteMutationVariables = {
    input: UpdateSiteInput;
};
export type UpdateSiteMutationResponse = {
    readonly updateSite: {
        readonly site: {
            readonly id: string;
            readonly name: string;
            readonly createdAt: string;
            readonly allowedOrigins: ReadonlyArray<string>;
        };
        readonly clientMutationId: string;
    };
};
export type UpdateSiteMutation = {
    readonly response: UpdateSiteMutationResponse;
    readonly variables: UpdateSiteMutationVariables;
};



/*
mutation UpdateSiteMutation(
  $input: UpdateSiteInput!
) {
  updateSite(input: $input) {
    site {
      id
      name
      createdAt
      allowedOrigins
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateSitePayload",
            "kind": "LinkedField",
            "name": "updateSite",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowedOrigins",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateSiteMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateSiteMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "0244a685696d16397147b08222791792",
            "metadata": {},
            "name": "UpdateSiteMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'd565043e34fb5d19d6aad55585565f45';
export default node;
