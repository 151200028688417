/* tslint:disable */
/* eslint-disable */
/* @relayHash 7375b4ece65e6fce8d143638a411962f */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_SORT = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "REACTION_DESC" | "REPLIES_DESC" | "%future added value";
export type SectionFilter = {
    name?: string | null;
};
export type ForReviewQueueRouteQueryVariables = {
    storyID?: string | null;
    siteID?: string | null;
    section?: SectionFilter | null;
    initialOrderBy?: COMMENT_SORT | null;
};
export type ForReviewQueueRouteQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ForReviewQueueRoute_query">;
};
export type ForReviewQueueRouteQuery = {
    readonly response: ForReviewQueueRouteQueryResponse;
    readonly variables: ForReviewQueueRouteQueryVariables;
};



/*
query ForReviewQueueRouteQuery(
  $storyID: ID
  $siteID: ID
  $section: SectionFilter
  $initialOrderBy: COMMENT_SORT
) {
  ...ForReviewQueueRoute_query_28QY37
}

fragment ForReviewQueueRoute_query_28QY37 on Query {
  flags(first: 15, orderBy: $initialOrderBy, storyID: $storyID, siteID: $siteID, section: $section) {
    edges {
      node {
        id
        ...ForReviewQueueRowContainer_flag
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  viewer {
    id
  }
  settings {
    forReviewQueue
    id
  }
}

fragment ForReviewQueueRowContainer_flag on Flag {
  id
  createdAt
  flagger {
    username
    id
  }
  reason
  additionalDetails
  reviewed
  revision {
    body
    id
  }
  comment {
    author {
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "initialOrderBy"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "section"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteID"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storyID"
    } as any), v4 = ({
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "initialOrderBy"
    } as any), v5 = ({
        "kind": "Variable",
        "name": "section",
        "variableName": "section"
    } as any), v6 = ({
        "kind": "Variable",
        "name": "siteID",
        "variableName": "siteID"
    } as any), v7 = ({
        "kind": "Variable",
        "name": "storyID",
        "variableName": "storyID"
    } as any), v8 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 15
        } as any),
        (v4 /*: any*/),
        (v5 /*: any*/),
        (v6 /*: any*/),
        (v7 /*: any*/)
    ], v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ForReviewQueueRouteQuery",
            "selections": [
                {
                    "args": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "ForReviewQueueRoute_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v3 /*: any*/),
                (v2 /*: any*/),
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ForReviewQueueRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "concreteType": "FlagsConnection",
                    "kind": "LinkedField",
                    "name": "flags",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FlagEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Flag",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "flagger",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "username",
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "reason",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "additionalDetails",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "reviewed",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CommentRevision",
                                            "kind": "LinkedField",
                                            "name": "revision",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "body",
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "comment",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "author",
                                                    "plural": false,
                                                    "selections": [
                                                        (v9 /*: any*/),
                                                        {
                                                            "kind": "ClientExtension",
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "allCommentsRejected",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "commentsRejectedOnSites",
                                                                    "storageKey": null
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "filters": [
                        "orderBy",
                        "storyID",
                        "siteID",
                        "section"
                    ],
                    "handle": "connection",
                    "key": "ForReviewQueue_flags",
                    "kind": "LinkedHandle",
                    "name": "flags"
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "forReviewQueue",
                            "storageKey": null
                        },
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "7375b4ece65e6fce8d143638a411962f",
            "metadata": {},
            "name": "ForReviewQueueRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'a181ac8912a1a17defeff88721043c3b';
export default node;
