/* tslint:disable */
/* eslint-disable */
/* @relayHash 2cd39f85ebc450255886b5e88a1ada15 */

import { ConcreteRequest } from "relay-runtime";
export type CreateFlairBadgeInput = {
    clientMutationId: string;
    name: string;
    url: string;
};
export type CreateFlairBadgeMutationVariables = {
    input: CreateFlairBadgeInput;
};
export type CreateFlairBadgeMutationResponse = {
    readonly createFlairBadge: {
        readonly settings: {
            readonly flairBadges: {
                readonly flairBadgesEnabled: boolean | null;
                readonly badges: ReadonlyArray<{
                    readonly name: string;
                    readonly url: string;
                }>;
            } | null;
        };
        readonly clientMutationId: string;
    };
};
export type CreateFlairBadgeMutation = {
    readonly response: CreateFlairBadgeMutationResponse;
    readonly variables: CreateFlairBadgeMutationVariables;
};



/*
mutation CreateFlairBadgeMutation(
  $input: CreateFlairBadgeInput!
) {
  createFlairBadge(input: $input) {
    settings {
      flairBadges {
        flairBadgesEnabled
        badges {
          name
          url
        }
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "concreteType": "FlairBadgeConfiguration",
        "kind": "LinkedField",
        "name": "flairBadges",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "flairBadgesEnabled",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "FlairBadge",
                "kind": "LinkedField",
                "name": "badges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateFlairBadgeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateFlairBadgePayload",
                    "kind": "LinkedField",
                    "name": "createFlairBadge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateFlairBadgeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateFlairBadgePayload",
                    "kind": "LinkedField",
                    "name": "createFlairBadge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "2cd39f85ebc450255886b5e88a1ada15",
            "metadata": {},
            "name": "CreateFlairBadgeMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'fe419035657dd2e263db1f5f040c8473';
export default node;
