/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalLinksConfigContainer_formValues = {
    readonly externalProfileURL: string | null;
    readonly " $refType": "ExternalLinksConfigContainer_formValues";
};
export type ExternalLinksConfigContainer_formValues$data = ExternalLinksConfigContainer_formValues;
export type ExternalLinksConfigContainer_formValues$key = {
    readonly " $data"?: ExternalLinksConfigContainer_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalLinksConfigContainer_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalLinksConfigContainer_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalProfileURL",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'ac099dc208adc88816fe706ff8cde1a7';
export default node;
