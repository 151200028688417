"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Function decorator that prevents calling `fn` more then once per frame.
 * If called more than once, the last return value gets returned.
 */
const oncePerFrame = (fn, toggledThisFrame, setToggledThisFrame) => {
    let lastResult = null;
    return ((...args) => {
        if (toggledThisFrame) {
            return lastResult;
        }
        setToggledThisFrame(true);
        lastResult = fn(...args);
        setTimeout(() => setToggledThisFrame(false), 0);
    });
};
exports.default = oncePerFrame;
