/* tslint:disable */
/* eslint-disable */
/* @relayHash 60b64327ada32bd0609b63378e6425bb */

import { ConcreteRequest } from "relay-runtime";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
export type CloseStoryInput = {
    id: string;
    clientMutationId: string;
};
export type CloseStoryMutationVariables = {
    input: CloseStoryInput;
};
export type CloseStoryMutationResponse = {
    readonly closeStory: {
        readonly story: {
            readonly id: string;
            readonly status: STORY_STATUS;
            readonly closedAt: string | null;
            readonly isClosed: boolean;
            readonly isArchiving: boolean;
            readonly isArchived: boolean;
        } | null;
        readonly clientMutationId: string;
    };
};
export type CloseStoryMutation = {
    readonly response: CloseStoryMutationResponse;
    readonly variables: CloseStoryMutationVariables;
};



/*
mutation CloseStoryMutation(
  $input: CloseStoryInput!
) {
  closeStory(input: $input) {
    story {
      id
      status
      closedAt
      isClosed
      isArchiving
      isArchived
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CloseStoryPayload",
            "kind": "LinkedField",
            "name": "closeStory",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "story",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "closedAt",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isClosed",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchiving",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchived",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CloseStoryMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CloseStoryMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "60b64327ada32bd0609b63378e6425bb",
            "metadata": {},
            "name": "CloseStoryMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'fd32405f43d4712cc8395bcb1d1bb951';
export default node;
