/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MemberBioConfig_formValues = {
    readonly memberBios: boolean;
    readonly " $refType": "MemberBioConfig_formValues";
};
export type MemberBioConfig_formValues$data = MemberBioConfig_formValues;
export type MemberBioConfig_formValues$key = {
    readonly " $data"?: MemberBioConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"MemberBioConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberBioConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "memberBios",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '1baad4438640236b5cc68a0d8dd05cde';
export default node;
