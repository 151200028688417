"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const he_1 = require("he");
/**
 * getHTMLPlainText returns text representation of html.
 *
 * @param html string
 */
function getHTMLPlainText(html) {
    const htmlWithNewLine = html.replace(/((<\/(ul|li|blockquote)>)|(<\/(div|p)>\s*(<[^/]))|(<br>(?!\s*<\/(div|p|li|blockquote))))/g, "\n$1");
    let textContent;
    if (process.env.WEBPACK !== "true") {
        // textContent is not fully implemented in JSDOM, so we use `striptags`
        // instead, and `he.decode` to decode HTML Entities.
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        textContent = he_1.decode(require("striptags")(htmlWithNewLine));
    }
    else {
        // eslint-disable-next-line no-restricted-globals
        const divElement = window.document.createElement("div");
        divElement.innerHTML = htmlWithNewLine;
        textContent = divElement.textContent || "";
    }
    // Trim the text content.
    return textContent.trim();
}
exports.default = getHTMLPlainText;
