/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SessionConfig_formValues = {
    readonly sessionDuration: number;
    readonly " $refType": "SessionConfig_formValues";
};
export type SessionConfig_formValues$data = SessionConfig_formValues;
export type SessionConfig_formValues$key = {
    readonly " $data"?: SessionConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"SessionConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sessionDuration",
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any);
(node as any).hash = '613b91f551c61ec11001db27756e30b7';
export default node;
