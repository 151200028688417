/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DSA_METHOD_OF_REDRESS = "EMAIL" | "NONE" | "URL" | "%future added value";
export type DSAConfigContainer_settings = {
    readonly dsa: {
        readonly enabled: boolean;
        readonly methodOfRedress: {
            readonly method: DSA_METHOD_OF_REDRESS;
            readonly url: string | null;
            readonly email: string | null;
        };
    };
    readonly " $refType": "DSAConfigContainer_settings";
};
export type DSAConfigContainer_settings$data = DSAConfigContainer_settings;
export type DSAConfigContainer_settings$key = {
    readonly " $data"?: DSAConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"DSAConfigContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DSAConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "DSAConfiguration",
            "kind": "LinkedField",
            "name": "dsa",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DSAMethodOfRedressConfiguration",
                    "kind": "LinkedField",
                    "name": "methodOfRedress",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "method",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '98623b7a41843c3403cdf2fb60243590';
export default node;
