/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalModerationPhaseDangerZone_phase = {
    readonly id: string;
    readonly enabled: boolean;
    readonly " $refType": "ExternalModerationPhaseDangerZone_phase";
};
export type ExternalModerationPhaseDangerZone_phase$data = ExternalModerationPhaseDangerZone_phase;
export type ExternalModerationPhaseDangerZone_phase$key = {
    readonly " $data"?: ExternalModerationPhaseDangerZone_phase$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalModerationPhaseDangerZone_phase">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalModerationPhaseDangerZone_phase",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
        }
    ],
    "type": "ExternalModerationPhase",
    "abstractKey": null
} as any);
(node as any).hash = '9cbeb133a8cc4df406650290a24657c2';
export default node;
