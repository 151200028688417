/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type ReportedComment_dsaReport = {
    readonly id: string;
    readonly comment: {
        readonly id: string;
        readonly deleted: boolean | null;
        readonly parent: {
            readonly author: {
                readonly id: string;
                readonly username: string | null;
            } | null;
        } | null;
        readonly author: {
            readonly id: string;
            readonly username: string | null;
        } | null;
        readonly createdAt: string;
        readonly editing: {
            readonly edited: boolean;
        };
        readonly rating: number | null;
        readonly body: string | null;
        readonly story: {
            readonly url: string;
            readonly metadata: {
                readonly title: string | null;
            } | null;
        };
        readonly status: COMMENT_STATUS;
        readonly " $fragmentRefs": FragmentRefs<"CommentAuthorContainer_comment" | "MediaContainer_comment">;
    } | null;
    readonly " $refType": "ReportedComment_dsaReport";
};
export type ReportedComment_dsaReport$data = ReportedComment_dsaReport;
export type ReportedComment_dsaReport$key = {
    readonly " $data"?: ReportedComment_dsaReport$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportedComment_dsaReport">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ReportedComment_dsaReport",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "comment",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deleted",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Comment",
                        "kind": "LinkedField",
                        "name": "parent",
                        "plural": false,
                        "selections": [
                            (v1 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "EditInfo",
                        "kind": "LinkedField",
                        "name": "editing",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "edited",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rating",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Story",
                        "kind": "LinkedField",
                        "name": "story",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "StoryMetadata",
                                "kind": "LinkedField",
                                "name": "metadata",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CommentAuthorContainer_comment"
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MediaContainer_comment"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "DSAReport",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '476bd522e5b547a790470254aadbe189';
export default node;
