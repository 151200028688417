/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConfigureExternalModerationPhaseContainer_phase = {
    readonly " $fragmentRefs": FragmentRefs<"ExternalModerationPhaseDetails_phase" | "ExternalModerationPhaseDangerZone_phase" | "ExternalModerationPhaseStatus_phase">;
    readonly " $refType": "ConfigureExternalModerationPhaseContainer_phase";
};
export type ConfigureExternalModerationPhaseContainer_phase$data = ConfigureExternalModerationPhaseContainer_phase;
export type ConfigureExternalModerationPhaseContainer_phase$key = {
    readonly " $data"?: ConfigureExternalModerationPhaseContainer_phase$data;
    readonly " $fragmentRefs": FragmentRefs<"ConfigureExternalModerationPhaseContainer_phase">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureExternalModerationPhaseContainer_phase",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalModerationPhaseDetails_phase"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalModerationPhaseDangerZone_phase"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalModerationPhaseStatus_phase"
        }
    ],
    "type": "ExternalModerationPhase",
    "abstractKey": null
} as any);
(node as any).hash = 'ca8999f442805d150a8bc0d886479e8a';
export default node;
