/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GIF_MEDIA_SOURCE = "GIPHY" | "TENOR" | "%future added value";
export type MediaLinksConfig_formValues = {
    readonly media: {
        readonly twitter: {
            readonly enabled: boolean;
        };
        readonly youtube: {
            readonly enabled: boolean;
        };
        readonly gifs: {
            readonly enabled: boolean;
            readonly maxRating: string | null;
            readonly key: string | null;
            readonly provider: GIF_MEDIA_SOURCE | null;
        };
    };
    readonly " $refType": "MediaLinksConfig_formValues";
};
export type MediaLinksConfig_formValues$data = MediaLinksConfig_formValues;
export type MediaLinksConfig_formValues$key = {
    readonly " $data"?: MediaLinksConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"MediaLinksConfig_formValues">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any), v1 = [
        (v0 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MediaLinksConfig_formValues",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "MediaConfiguration",
                "kind": "LinkedField",
                "name": "media",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TwitterMediaConfiguration",
                        "kind": "LinkedField",
                        "name": "twitter",
                        "plural": false,
                        "selections": (v1 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "YouTubeMediaConfiguration",
                        "kind": "LinkedField",
                        "name": "youtube",
                        "plural": false,
                        "selections": (v1 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "GifMediaConfiguration",
                        "kind": "LinkedField",
                        "name": "gifs",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maxRating",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "key",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "provider",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Settings",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '7d23d8aa006dfd4a4cbcdc777702e010';
export default node;
