/* tslint:disable */
/* eslint-disable */
/* @relayHash dfd5f6abaf0602cc177b79f3778dc918 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AddWebhookEndpointRouteQueryVariables = {};
export type AddWebhookEndpointRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"AddWebhookEndpointContainer_settings">;
    };
};
export type AddWebhookEndpointRouteQuery = {
    readonly response: AddWebhookEndpointRouteQueryResponse;
    readonly variables: AddWebhookEndpointRouteQueryVariables;
};



/*
query AddWebhookEndpointRouteQuery {
  settings {
    ...AddWebhookEndpointContainer_settings
    id
  }
}

fragment AddWebhookEndpointContainer_settings on Settings {
  ...ConfigureWebhookEndpointForm_settings
}

fragment ConfigureWebhookEndpointForm_settings on Settings {
  ...EventsSelectField_settings
}

fragment EventsSelectField_settings on Settings {
  webhookEvents
}
*/

const node: ConcreteRequest = ({
    "fragment": {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AddWebhookEndpointRouteQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Settings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "AddWebhookEndpointContainer_settings"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    },
    "kind": "Request",
    "operation": {
        "argumentDefinitions": [],
        "kind": "Operation",
        "name": "AddWebhookEndpointRouteQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Settings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "webhookEvents",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ]
    },
    "params": {
        "id": "dfd5f6abaf0602cc177b79f3778dc918",
        "metadata": {},
        "name": "AddWebhookEndpointRouteQuery",
        "operationKind": "query",
        "text": null
    }
} as any);
(node as any).hash = '75bc47a8bfcf1ef7c1e4fe78329c691e';
export default node;
