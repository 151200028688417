/* tslint:disable */
/* eslint-disable */
/* @relayHash 4e72da7dec453f2b5435eec076252ac3 */

import { ConcreteRequest } from "relay-runtime";
export type DSAReportDecisionLegality = "ILLEGAL" | "LEGAL" | "%future added value";
export type DSAReportHistoryType = "DECISION_MADE" | "NOTE" | "SHARE" | "STATUS_CHANGED" | "%future added value";
export type DSAReportStatus = "AWAITING_REVIEW" | "COMPLETED" | "UNDER_REVIEW" | "VOID" | "%future added value";
export type MakeDSAReportDecisionInput = {
    userID: string;
    legality: DSAReportDecisionLegality;
    legalGrounds?: string | null;
    detailedExplanation?: string | null;
    commentID: string;
    commentRevisionID: string;
    reportID: string;
    clientMutationId: string;
};
export type MakeReportDecisionMutationVariables = {
    input: MakeDSAReportDecisionInput;
};
export type MakeReportDecisionMutationResponse = {
    readonly makeDSAReportDecision: {
        readonly dsaReport: {
            readonly id: string;
            readonly status: DSAReportStatus | null;
            readonly decision: {
                readonly legality: DSAReportDecisionLegality;
                readonly legalGrounds: string | null;
                readonly detailedExplanation: string | null;
            } | null;
            readonly history: ReadonlyArray<{
                readonly id: string;
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
                readonly createdAt: string;
                readonly body: string | null;
                readonly type: DSAReportHistoryType | null;
                readonly status: DSAReportStatus | null;
                readonly decision: {
                    readonly legality: DSAReportDecisionLegality;
                    readonly legalGrounds: string | null;
                    readonly detailedExplanation: string | null;
                } | null;
            } | null> | null;
        } | null;
        readonly clientMutationId: string;
    };
};
export type MakeReportDecisionMutation = {
    readonly response: MakeReportDecisionMutationResponse;
    readonly variables: MakeReportDecisionMutationVariables;
};



/*
mutation MakeReportDecisionMutation(
  $input: MakeDSAReportDecisionInput!
) {
  makeDSAReportDecision(input: $input) {
    dsaReport {
      id
      status
      decision {
        legality
        legalGrounds
        detailedExplanation
      }
      history {
        id
        createdBy {
          username
          id
        }
        createdAt
        body
        type
        status
        decision {
          legality
          legalGrounds
          detailedExplanation
        }
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "concreteType": "DSAReportDecision",
        "kind": "LinkedField",
        "name": "decision",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legality",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalGrounds",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detailedExplanation",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "MakeReportDecisionMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "MakeDSAReportDecisionPayload",
                    "kind": "LinkedField",
                    "name": "makeDSAReportDecision",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReport",
                            "kind": "LinkedField",
                            "name": "dsaReport",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAReportHistoryItem",
                                    "kind": "LinkedField",
                                    "name": "history",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "createdBy",
                                            "plural": false,
                                            "selections": [
                                                (v5 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "MakeReportDecisionMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "MakeDSAReportDecisionPayload",
                    "kind": "LinkedField",
                    "name": "makeDSAReportDecision",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReport",
                            "kind": "LinkedField",
                            "name": "dsaReport",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAReportHistoryItem",
                                    "kind": "LinkedField",
                                    "name": "history",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "createdBy",
                                            "plural": false,
                                            "selections": [
                                                (v5 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "4e72da7dec453f2b5435eec076252ac3",
            "metadata": {},
            "name": "MakeReportDecisionMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '20b34b38c8ae6c722f1f7e7af9138249';
export default node;
