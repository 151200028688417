/* tslint:disable */
/* eslint-disable */
/* @relayHash e04897932198cc30698daabcd73b45e6 */

import { ConcreteRequest } from "relay-runtime";
export type TestSMTPInput = {
    clientMutationId: string;
};
export type TestSMTPMutationVariables = {
    input: TestSMTPInput;
};
export type TestSMTPMutationResponse = {
    readonly testSMTP: {
        readonly clientMutationId: string;
    };
};
export type TestSMTPMutation = {
    readonly response: TestSMTPMutationResponse;
    readonly variables: TestSMTPMutationVariables;
};



/*
mutation TestSMTPMutation(
  $input: TestSMTPInput!
) {
  testSMTP(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "TestSMTPPayload",
            "kind": "LinkedField",
            "name": "testSMTP",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TestSMTPMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TestSMTPMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "e04897932198cc30698daabcd73b45e6",
            "metadata": {},
            "name": "TestSMTPMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'a075622643af571c1ee9ac313ddf3753';
export default node;
