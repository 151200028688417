/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_FLAG_REASON = "COMMENT_DETECTED_BANNED_WORD" | "COMMENT_DETECTED_LINKS" | "COMMENT_DETECTED_NEW_COMMENTER" | "COMMENT_DETECTED_PREMOD_USER" | "COMMENT_DETECTED_RECENT_HISTORY" | "COMMENT_DETECTED_REPEAT_POST" | "COMMENT_DETECTED_SPAM" | "COMMENT_DETECTED_SUSPECT_WORD" | "COMMENT_DETECTED_TOXIC" | "COMMENT_REPORTED_ABUSIVE" | "COMMENT_REPORTED_BIO" | "COMMENT_REPORTED_OFFENSIVE" | "COMMENT_REPORTED_OTHER" | "COMMENT_REPORTED_SPAM" | "%future added value";
export type ForReviewQueueRowContainer_flag = {
    readonly id: string;
    readonly createdAt: string;
    readonly flagger: {
        readonly username: string | null;
    } | null;
    readonly reason: COMMENT_FLAG_REASON | null;
    readonly additionalDetails: string | null;
    readonly reviewed: boolean;
    readonly revision: {
        readonly body: string | null;
    } | null;
    readonly comment: {
        readonly author: {
            readonly allCommentsRejected: boolean | null;
            readonly commentsRejectedOnSites: ReadonlyArray<string> | null;
        } | null;
        readonly id: string;
    };
    readonly " $refType": "ForReviewQueueRowContainer_flag";
};
export type ForReviewQueueRowContainer_flag$data = ForReviewQueueRowContainer_flag;
export type ForReviewQueueRowContainer_flag$key = {
    readonly " $data"?: ForReviewQueueRowContainer_flag$data;
    readonly " $fragmentRefs": FragmentRefs<"ForReviewQueueRowContainer_flag">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ForReviewQueueRowContainer_flag",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "flagger",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reason",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "additionalDetails",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reviewed",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentRevision",
                "kind": "LinkedField",
                "name": "revision",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "comment",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ClientExtension",
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "allCommentsRejected",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "commentsRejectedOnSites",
                                        "storageKey": null
                                    }
                                ]
                            }
                        ],
                        "storageKey": null
                    },
                    (v0 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "type": "Flag",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '9ce51643a5a5580d8acbc56e4138021e';
export default node;
