"use strict";
/**
 * TIME represents various constants for second representations of times.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TIME = void 0;
var TIME;
(function (TIME) {
    TIME[TIME["SECOND"] = 1] = "SECOND";
    TIME[TIME["MINUTE"] = 60] = "MINUTE";
    TIME[TIME["HOUR"] = 3600] = "HOUR";
    TIME[TIME["DAY"] = 86400] = "DAY";
    TIME[TIME["WEEK"] = 604800] = "WEEK";
})(TIME = exports.TIME || (exports.TIME = {}));
exports.default = TIME;
