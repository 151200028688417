/* tslint:disable */
/* eslint-disable */
/* @relayHash aab6e1ddcbaf301f59b04fd0a5629992 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdvancedConfigRouteQueryVariables = {};
export type AdvancedConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"AdvancedConfigContainer_settings">;
    };
};
export type AdvancedConfigRouteQuery = {
    readonly response: AdvancedConfigRouteQueryResponse;
    readonly variables: AdvancedConfigRouteQueryVariables;
};



/*
query AdvancedConfigRouteQuery {
  settings {
    ...AdvancedConfigContainer_settings
    id
  }
}

fragment AdvancedConfigContainer_settings on Settings {
  customFontsCSSURL
  customCSSURL
  embeddedComments {
    allowReplies
    oEmbedAllowedOrigins
  }
  live {
    enabled
  }
  stories {
    scraping {
      enabled
      proxyURL
      customUserAgent
      authentication
      username
      password
    }
    disableLazy
  }
  ...CommentStreamLiveUpdatesContainer_settings
  amp
  forReviewQueue
}

fragment CommentStreamLiveUpdatesContainer_settings on Settings {
  live {
    configurable
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AdvancedConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AdvancedConfigContainer_settings"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AdvancedConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "customFontsCSSURL",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "customCSSURL",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmbeddedCommentsConfiguration",
                            "kind": "LinkedField",
                            "name": "embeddedComments",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "allowReplies",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "oEmbedAllowedOrigins",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "LiveConfiguration",
                            "kind": "LinkedField",
                            "name": "live",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configurable",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "StoryConfiguration",
                            "kind": "LinkedField",
                            "name": "stories",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "StoryScrapingConfiguration",
                                    "kind": "LinkedField",
                                    "name": "scraping",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "proxyURL",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "customUserAgent",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "authentication",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "username",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "password",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "disableLazy",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amp",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "forReviewQueue",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "aab6e1ddcbaf301f59b04fd0a5629992",
            "metadata": {},
            "name": "AdvancedConfigRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'ca6346567a101832b8b3826d41af699c';
export default node;
