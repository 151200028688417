/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type View = "ADD_EMAIL_ADDRESS" | "CREATE_PASSWORD" | "CREATE_USERNAME" | "LINK_ACCOUNT" | "SIGN_IN" | "%future added value";
export type LoginRouteLocal = {
    readonly authView: View | null;
    readonly " $refType": "LoginRouteLocal";
};
export type LoginRouteLocal$data = LoginRouteLocal;
export type LoginRouteLocal$key = {
    readonly " $data"?: LoginRouteLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"LoginRouteLocal">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginRouteLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authView",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = '5994ccee5a031fc78c89c9841fb14594';
export default node;
