/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FacebookConfigContainer_auth = {
    readonly integrations: {
        readonly facebook: {
            readonly callbackURL: string;
        };
    };
    readonly " $refType": "FacebookConfigContainer_auth";
};
export type FacebookConfigContainer_auth$data = FacebookConfigContainer_auth;
export type FacebookConfigContainer_auth$key = {
    readonly " $data"?: FacebookConfigContainer_auth$data;
    readonly " $fragmentRefs": FragmentRefs<"FacebookConfigContainer_auth">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FacebookConfigContainer_auth",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "FacebookAuthIntegration",
                    "kind": "LinkedField",
                    "name": "facebook",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "callbackURL",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any);
(node as any).hash = '3d02c49a25efe29c138af15dee97050d';
export default node;
