/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DSAReportDecisionLegality = "ILLEGAL" | "LEGAL" | "%future added value";
export type DSAReportHistoryType = "DECISION_MADE" | "NOTE" | "SHARE" | "STATUS_CHANGED" | "%future added value";
export type DSAReportStatus = "AWAITING_REVIEW" | "COMPLETED" | "UNDER_REVIEW" | "VOID" | "%future added value";
export type ReportHistory_dsaReport = {
    readonly id: string;
    readonly status: DSAReportStatus | null;
    readonly createdAt: string;
    readonly history: ReadonlyArray<{
        readonly id: string;
        readonly createdBy: {
            readonly username: string | null;
        } | null;
        readonly createdAt: string;
        readonly body: string | null;
        readonly type: DSAReportHistoryType | null;
        readonly status: DSAReportStatus | null;
        readonly decision: {
            readonly legality: DSAReportDecisionLegality;
            readonly legalGrounds: string | null;
            readonly detailedExplanation: string | null;
        } | null;
    } | null> | null;
    readonly " $refType": "ReportHistory_dsaReport";
};
export type ReportHistory_dsaReport$data = ReportHistory_dsaReport;
export type ReportHistory_dsaReport$key = {
    readonly " $data"?: ReportHistory_dsaReport$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportHistory_dsaReport">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ReportHistory_dsaReport",
        "selections": [
            (v0 /*: any*/),
            (v1 /*: any*/),
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "DSAReportHistoryItem",
                "kind": "LinkedField",
                "name": "history",
                "plural": true,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                    },
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "DSAReportDecision",
                        "kind": "LinkedField",
                        "name": "decision",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "legality",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "legalGrounds",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "detailedExplanation",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "DSAReport",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '257c2c357378cdd1b7f45f8f7ef48d42';
export default node;
