/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserBadgesContainer_user = {
    readonly badges: ReadonlyArray<string> | null;
    readonly " $refType": "UserBadgesContainer_user";
};
export type UserBadgesContainer_user$data = UserBadgesContainer_user;
export type UserBadgesContainer_user$key = {
    readonly " $data"?: UserBadgesContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserBadgesContainer_user">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserBadgesContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "badges",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '4c39ffc767e945d17d222882f6c0d51e';
export default node;
