/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserRowContainer_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"UserStatusChangeContainer_viewer" | "UserRoleChangeContainer_viewer">;
    readonly " $refType": "UserRowContainer_viewer";
};
export type UserRowContainer_viewer$data = UserRowContainer_viewer;
export type UserRowContainer_viewer$key = {
    readonly " $data"?: UserRowContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserRowContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRowContainer_viewer",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserStatusChangeContainer_viewer"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserRoleChangeContainer_viewer"
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = 'a5c3e17185355d06bc50fbd6dff69605';
export default node;
