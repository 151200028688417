"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var validatePermissionsAction_1 = require("./validatePermissionsAction");
Object.defineProperty(exports, "validatePermissionsAction", { enumerable: true, get: function () { return validatePermissionsAction_1.validatePermissionsAction; } });
var validateRoleChange_1 = require("./validateRoleChange");
Object.defineProperty(exports, "validateRoleChange", { enumerable: true, get: function () { return validateRoleChange_1.validateRoleChange; } });
var validateScopeChange_1 = require("./validateScopeChange");
Object.defineProperty(exports, "validateScopeChange", { enumerable: true, get: function () { return validateScopeChange_1.validateScopeChange; } });
var isSiteModerationScoped_1 = require("./isSiteModerationScoped");
Object.defineProperty(exports, "isSiteModerationScoped", { enumerable: true, get: function () { return isSiteModerationScoped_1.isSiteModerationScoped; } });
