"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * IMAGE_EXTENSIONS are the extensions associated with supported images.
 */
const IMAGE_EXTENSIONS = /\.(jpe?g|gif|png|webp)$/i;
function validateImagePathname(pathname) {
    return IMAGE_EXTENSIONS.test(pathname);
}
exports.default = validateImagePathname;
