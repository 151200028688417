/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RecentHistoryContainer_settings = {
    readonly recentCommentHistory: {
        readonly enabled: boolean;
        readonly timeFrame: number;
        readonly triggerRejectionRate: number;
    };
    readonly " $refType": "RecentHistoryContainer_settings";
};
export type RecentHistoryContainer_settings$data = RecentHistoryContainer_settings;
export type RecentHistoryContainer_settings$key = {
    readonly " $data"?: RecentHistoryContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"RecentHistoryContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecentHistoryContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "RecentCommentHistoryConfiguration",
            "kind": "LinkedField",
            "name": "recentCommentHistory",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeFrame",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "triggerRejectionRate",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '27f8e0e6edd59da21085db1403adb418';
export default node;
