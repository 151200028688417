/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BannedWordListConfig_formValues = {
    readonly wordList: {
        readonly banned: ReadonlyArray<string>;
    };
    readonly " $refType": "BannedWordListConfig_formValues";
};
export type BannedWordListConfig_formValues$data = BannedWordListConfig_formValues;
export type BannedWordListConfig_formValues$key = {
    readonly " $data"?: BannedWordListConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"BannedWordListConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BannedWordListConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "WordList",
            "kind": "LinkedField",
            "name": "wordList",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banned",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '4b57e274f511f8f121ed7556696e06c8';
export default node;
