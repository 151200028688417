/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NavigationContainer_settings = {
    readonly multisite: boolean;
    readonly " $refType": "NavigationContainer_settings";
};
export type NavigationContainer_settings$data = NavigationContainer_settings;
export type NavigationContainer_settings$key = {
    readonly " $data"?: NavigationContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"NavigationContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multisite",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '39236c573c1fedee7ccddc1dd58969ac';
export default node;
