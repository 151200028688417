/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerateStoryButton_local = {
    readonly accessToken: string | null;
    readonly " $refType": "ModerateStoryButton_local";
};
export type ModerateStoryButton_local$data = ModerateStoryButton_local;
export type ModerateStoryButton_local$key = {
    readonly " $data"?: ModerateStoryButton_local$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateStoryButton_local">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateStoryButton_local",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessToken",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = 'b032fb0da371ff0e7f74671079a4df75';
export default node;
