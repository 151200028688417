/* tslint:disable */
/* eslint-disable */
/* @relayHash 3a39fd53d93696f05f4591691cb5dbda */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReactionDetailsQueryVariables = {
    commentID: string;
};
export type ReactionDetailsQueryResponse = {
    readonly comment: {
        readonly " $fragmentRefs": FragmentRefs<"ReactionDetailsContainer_comment">;
    } | null;
};
export type ReactionDetailsQuery = {
    readonly response: ReactionDetailsQueryResponse;
    readonly variables: ReactionDetailsQueryVariables;
};



/*
query ReactionDetailsQuery(
  $commentID: ID!
) {
  comment(id: $commentID) {
    ...ReactionDetailsContainer_comment
    id
  }
}

fragment ReactionDetailsContainer_comment on Comment {
  id
  reactions(first: 10) {
    edges {
      node {
        id
        reacter {
          userID
          username
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "commentID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "commentID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 10
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ReactionDetailsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ReactionDetailsContainer_comment"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ReactionDetailsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": (v3 /*: any*/),
                            "concreteType": "ReactionsConnection",
                            "kind": "LinkedField",
                            "name": "reactions",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReactionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Reaction",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Reacter",
                                                    "kind": "LinkedField",
                                                    "name": "reacter",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "userID",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "username",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "reactions(first:10)"
                        },
                        {
                            "alias": null,
                            "args": (v3 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "ReactionDetails_reactions",
                            "kind": "LinkedHandle",
                            "name": "reactions"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "3a39fd53d93696f05f4591691cb5dbda",
            "metadata": {},
            "name": "ReactionDetailsQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '47d2ec474008373e84b49c2b40c549bf';
export default node;
