/* tslint:disable */
/* eslint-disable */
/* @relayHash 05da0c22b7941c345686f9b142a48439 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConversationModalContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
    commentID: string;
};
export type ConversationModalContainerPaginationQueryResponse = {
    readonly comment: {
        readonly " $fragmentRefs": FragmentRefs<"ConversationModalContainer_comment">;
    } | null;
};
export type ConversationModalContainerPaginationQuery = {
    readonly response: ConversationModalContainerPaginationQueryResponse;
    readonly variables: ConversationModalContainerPaginationQueryVariables;
};



/*
query ConversationModalContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $commentID: ID!
) {
  comment(id: $commentID) {
    ...ConversationModalContainer_comment_1G22uz
    id
  }
}

fragment ConversationModalCommentContainer_comment on Comment {
  id
  body
  createdAt
  author {
    username
    id
  }
  revision {
    id
  }
  status
  replyCount
  parent {
    author {
      username
      id
    }
    id
  }
  ...MediaContainer_comment
}

fragment ConversationModalContainer_comment_1G22uz on Comment {
  id
  ...ConversationModalCommentContainer_comment
  rootParent {
    id
  }
  parents(last: $count, before: $cursor) {
    edges {
      node {
        id
        ...ConversationModalCommentContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      hasPreviousPage
      startCursor
    }
  }
  parentCount
}

fragment MediaContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TenorMedia {
        url
        title
        width
        height
        tenorStill: still
        tenorVideo: video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "commentID"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v3 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "commentID"
        } as any)
    ], v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            (v4 /*: any*/)
        ],
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
    } as any), v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v14 = [
        (v9 /*: any*/)
    ], v15 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentRevision",
        "kind": "LinkedField",
        "name": "revision",
        "plural": false,
        "selections": [
            (v4 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "media",
                "plural": false,
                "selections": [
                    (v8 /*: any*/),
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v9 /*: any*/),
                            (v10 /*: any*/),
                            (v11 /*: any*/),
                            (v12 /*: any*/),
                            (v13 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "video",
                                "storageKey": null
                            }
                        ],
                        "type": "GiphyMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v9 /*: any*/),
                            (v10 /*: any*/),
                            (v11 /*: any*/),
                            (v12 /*: any*/),
                            {
                                "alias": "tenorStill",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "still",
                                "storageKey": null
                            },
                            {
                                "alias": "tenorVideo",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "video",
                                "storageKey": null
                            }
                        ],
                        "type": "TenorMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v14 /*: any*/),
                        "type": "TwitterMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v9 /*: any*/),
                            (v13 /*: any*/),
                            (v10 /*: any*/)
                        ],
                        "type": "YouTubeMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v14 /*: any*/),
                        "type": "ExternalMedia",
                        "abstractKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v16 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v17 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "replyCount",
        "storageKey": null
    } as any), v18 = ({
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "parent",
        "plural": false,
        "selections": [
            (v7 /*: any*/),
            (v4 /*: any*/)
        ],
        "storageKey": null
    } as any), v19 = [
        (v4 /*: any*/)
    ], v20 = ({
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": (v19 /*: any*/),
        "storageKey": null
    } as any), v21 = [
        ({
            "kind": "Variable",
            "name": "before",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "last",
            "variableName": "count"
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ConversationModalContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "ConversationModalContainer_comment"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ConversationModalContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v15 /*: any*/),
                        (v16 /*: any*/),
                        (v17 /*: any*/),
                        (v18 /*: any*/),
                        (v20 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "rootParent",
                            "plural": false,
                            "selections": (v19 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v21 /*: any*/),
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "parents",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                (v15 /*: any*/),
                                                (v16 /*: any*/),
                                                (v17 /*: any*/),
                                                (v18 /*: any*/),
                                                (v20 /*: any*/),
                                                (v8 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasPreviousPage",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "startCursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v21 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "ConversationModal_parents",
                            "kind": "LinkedHandle",
                            "name": "parents"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "parentCount",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "05da0c22b7941c345686f9b142a48439",
            "metadata": {},
            "name": "ConversationModalContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'af03e4592bf2a49fada2468a7c83e05e';
export default node;
