/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserHistoryDrawerAllComments_settings = {
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_settings">;
    readonly " $refType": "UserHistoryDrawerAllComments_settings";
};
export type UserHistoryDrawerAllComments_settings$data = UserHistoryDrawerAllComments_settings;
export type UserHistoryDrawerAllComments_settings$key = {
    readonly " $data"?: UserHistoryDrawerAllComments_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerAllComments_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHistoryDrawerAllComments_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'f3976ebccc7ad7e2b4c9e186f58513d5';
export default node;
