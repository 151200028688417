/* tslint:disable */
/* eslint-disable */
/* @relayHash 58fac2cb9bcb4f10523d5e51f8ab6218 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReportsRouteQueryVariables = {};
export type ReportsRouteQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ReportsTableContainer_query">;
};
export type ReportsRouteQuery = {
    readonly response: ReportsRouteQueryResponse;
    readonly variables: ReportsRouteQueryVariables;
};



/*
query ReportsRouteQuery {
  ...ReportsTableContainer_query
}

fragment ReportRowContainer_dsaReport on DSAReport {
  id
  createdAt
  referenceID
  status
  reporter {
    username
    id
  }
  comment {
    author {
      username
      id
    }
    id
  }
  lawBrokenDescription
  lastUpdated
}

fragment ReportsTableContainer_query on Query {
  dsaReports(first: 20, orderBy: CREATED_AT_DESC, status: [AWAITING_REVIEW, UNDER_REVIEW]) {
    edges {
      node {
        id
        ...ReportRowContainer_dsaReport
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 20
        } as any),
        ({
            "kind": "Literal",
            "name": "orderBy",
            "value": "CREATED_AT_DESC"
        } as any),
        ({
            "kind": "Literal",
            "name": "status",
            "value": [
                "AWAITING_REVIEW",
                "UNDER_REVIEW"
            ]
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        } as any),
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ReportsRouteQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ReportsTableContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ReportsRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "DSAReportsConnection",
                    "kind": "LinkedField",
                    "name": "dsaReports",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReportEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAReport",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "referenceID",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "reporter",
                                            "plural": false,
                                            "selections": (v2 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "comment",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "author",
                                                    "plural": false,
                                                    "selections": (v2 /*: any*/),
                                                    "storageKey": null
                                                },
                                                (v1 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lawBrokenDescription",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastUpdated",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "dsaReports(first:20,orderBy:\"CREATED_AT_DESC\",status:[\"AWAITING_REVIEW\",\"UNDER_REVIEW\"])"
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "filters": [
                        "orderBy",
                        "status"
                    ],
                    "handle": "connection",
                    "key": "ReportsTable_dsaReports",
                    "kind": "LinkedHandle",
                    "name": "dsaReports"
                }
            ]
        },
        "params": {
            "id": "58fac2cb9bcb4f10523d5e51f8ab6218",
            "metadata": {},
            "name": "ReportsRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '08328605d4d2d1ba0a9799b35c7476a6';
export default node;
