/* tslint:disable */
/* eslint-disable */
/* @relayHash 72d683ac41144e81ef2cfee53b43aa9a */

import { ConcreteRequest } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type UpdateUserRoleInput = {
    userID: string;
    role: USER_ROLE;
    scoped: boolean;
    clientMutationId: string;
};
export type UpdateUserRoleMutationVariables = {
    input: UpdateUserRoleInput;
};
export type UpdateUserRoleMutationResponse = {
    readonly updateUserRole: {
        readonly user: {
            readonly id: string;
            readonly role: USER_ROLE;
            readonly moderationScopes: {
                readonly scoped: boolean;
                readonly sites: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                }> | null;
            } | null;
            readonly membershipScopes: {
                readonly scoped: boolean;
                readonly sites: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                }> | null;
            } | null;
        };
        readonly clientMutationId: string;
    };
};
export type UpdateUserRoleMutation = {
    readonly response: UpdateUserRoleMutationResponse;
    readonly variables: UpdateUserRoleMutationVariables;
};



/*
mutation UpdateUserRoleMutation(
  $input: UpdateUserRoleInput!
) {
  updateUserRole(input: $input) {
    user {
      id
      role
      moderationScopes {
        scoped
        sites {
          id
          name
        }
      }
      membershipScopes {
        scoped
        sites {
          id
          name
        }
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scoped",
            "storageKey": null
        } as any),
        ({
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "sites",
            "plural": true,
            "selections": [
                (v1 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ], v3 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateUserRolePayload",
            "kind": "LinkedField",
            "name": "updateUserRole",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": (v2 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserMembershipScopes",
                            "kind": "LinkedField",
                            "name": "membershipScopes",
                            "plural": false,
                            "selections": (v2 /*: any*/),
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateUserRoleMutation",
            "selections": (v3 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateUserRoleMutation",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "id": "72d683ac41144e81ef2cfee53b43aa9a",
            "metadata": {},
            "name": "UpdateUserRoleMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '653b7a262d0669d60dd7c435c4674c41';
export default node;
