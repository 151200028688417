"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateScopeChange = void 0;
const isSiteModerationScoped_1 = require("./isSiteModerationScoped");
const types_1 = require("./types");
exports.validateScopeChange = ({ viewer, user, additions, deletions, }) => {
    // if viewer is admin, yes
    if (viewer.role === "ADMIN") {
        return true;
    }
    // only admins and mods can change scopes
    if (!types_1.isModerator(viewer)) {
        return false;
    }
    if (user.role === "ADMIN") {
        return false;
    }
    if (user.role === "MODERATOR" &&
        !isSiteModerationScoped_1.isSiteModerationScoped(user.moderationScopes)) {
        return false;
    }
    const invalidAdditions = isSiteModerationScoped_1.isSiteModerationScoped(viewer.moderationScopes) &&
        !!(additions === null || additions === void 0 ? void 0 : additions.find((addition) => { var _a, _b; return !((_b = (_a = viewer.moderationScopes) === null || _a === void 0 ? void 0 : _a.siteIDs) === null || _b === void 0 ? void 0 : _b.includes(addition)); }));
    const invalidDeletions = isSiteModerationScoped_1.isSiteModerationScoped(viewer.moderationScopes) &&
        !!(deletions === null || deletions === void 0 ? void 0 : deletions.find((deletion) => { var _a, _b; return !((_b = (_a = viewer.moderationScopes) === null || _a === void 0 ? void 0 : _a.siteIDs) === null || _b === void 0 ? void 0 : _b.includes(deletion)); }));
    // if user is unscoped, false
    if (user.role === "MODERATOR" &&
        !isSiteModerationScoped_1.isSiteModerationScoped(user.moderationScopes)) {
        return false;
    }
    return !(invalidAdditions || invalidDeletions);
};
