/* tslint:disable */
/* eslint-disable */
/* @relayHash 1ed643cda5e0ddac8dec80ade2adc02d */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteDashboardRouteQueryVariables = {
    siteID: string;
};
export type SiteDashboardRouteQueryResponse = {
    readonly site: {
        readonly name: string;
        readonly id: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"DashboardContainer_query">;
};
export type SiteDashboardRouteQuery = {
    readonly response: SiteDashboardRouteQueryResponse;
    readonly variables: SiteDashboardRouteQueryVariables;
};



/*
query SiteDashboardRouteQuery(
  $siteID: ID!
) {
  ...DashboardContainer_query
  site(id: $siteID) {
    name
    id
  }
}

fragment DashboardContainer_query on Query {
  sites(first: 20) {
    edges {
      node {
        id
        name
        ...DashboardSiteContainer_site
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  viewer {
    moderationScopes {
      scoped
      sites {
        id
        name
        ...DashboardSiteContainer_site
      }
    }
    id
  }
}

fragment DashboardSiteContainer_site on Site {
  id
  name
  createdAt
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "siteID"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": [
            {
                "kind": "Variable",
                "name": "id",
                "variableName": "siteID"
            }
        ],
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
            (v1 /*: any*/),
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any), v4 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 20
        } as any)
    ], v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SiteDashboardRouteQuery",
            "selections": [
                (v3 /*: any*/),
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DashboardContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SiteDashboardRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v1 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "sites(first:20)"
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "SitesConfig_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scoped",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v1 /*: any*/),
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                (v3 /*: any*/)
            ]
        },
        "params": {
            "id": "1ed643cda5e0ddac8dec80ade2adc02d",
            "metadata": {},
            "name": "SiteDashboardRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'b933fd07cd7ddc3fa069dc8698ee594d';
export default node;
