/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AccountCompletionContainer_viewer = {
    readonly username: string | null;
    readonly email: string | null;
    readonly duplicateEmail: string | null;
    readonly profiles: ReadonlyArray<{
        readonly __typename: string;
    }>;
    readonly " $refType": "AccountCompletionContainer_viewer";
};
export type AccountCompletionContainer_viewer$data = AccountCompletionContainer_viewer;
export type AccountCompletionContainer_viewer$key = {
    readonly " $data"?: AccountCompletionContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountCompletionContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountCompletionContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duplicateEmail",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profiles",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '52bf2ce3703fbc210b517f4c22358e30';
export default node;
