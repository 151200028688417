/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SignInWithOIDCContainer_auth = {
    readonly integrations: {
        readonly oidc: {
            readonly name: string | null;
            readonly redirectURL: string | null;
        };
    };
    readonly " $refType": "SignInWithOIDCContainer_auth";
};
export type SignInWithOIDCContainer_auth$data = SignInWithOIDCContainer_auth;
export type SignInWithOIDCContainer_auth$key = {
    readonly " $data"?: SignInWithOIDCContainer_auth$data;
    readonly " $fragmentRefs": FragmentRefs<"SignInWithOIDCContainer_auth">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInWithOIDCContainer_auth",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "OIDCAuthIntegration",
                    "kind": "LinkedField",
                    "name": "oidc",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "redirectURL",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any);
(node as any).hash = '5400cef70646880ee96d093625b3bc15';
export default node;
