/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_BODY_FORMAT = "HTML" | "PLAIN_TEXT" | "%future added value";
export type ConfigureExternalModerationPhaseForm_phase = {
    readonly id: string;
    readonly name: string;
    readonly url: string;
    readonly timeout: number;
    readonly format: COMMENT_BODY_FORMAT;
    readonly " $refType": "ConfigureExternalModerationPhaseForm_phase";
};
export type ConfigureExternalModerationPhaseForm_phase$data = ConfigureExternalModerationPhaseForm_phase;
export type ConfigureExternalModerationPhaseForm_phase$key = {
    readonly " $data"?: ConfigureExternalModerationPhaseForm_phase$data;
    readonly " $fragmentRefs": FragmentRefs<"ConfigureExternalModerationPhaseForm_phase">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureExternalModerationPhaseForm_phase",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeout",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "format",
            "storageKey": null
        }
    ],
    "type": "ExternalModerationPhase",
    "abstractKey": null
} as any);
(node as any).hash = 'b5a0f4acbc1c1aa38849ea5a33f6e8c5';
export default node;
