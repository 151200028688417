/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AddWebhookEndpointContainer_settings = {
    readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointForm_settings">;
    readonly " $refType": "AddWebhookEndpointContainer_settings";
};
export type AddWebhookEndpointContainer_settings$data = AddWebhookEndpointContainer_settings;
export type AddWebhookEndpointContainer_settings$key = {
    readonly " $data"?: AddWebhookEndpointContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"AddWebhookEndpointContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddWebhookEndpointContainer_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfigureWebhookEndpointForm_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'b5048516134af53b90f94f6afb6028d3';
export default node;
