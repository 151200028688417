/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_STATUS = "APPROVED" | "NONE" | "PREMOD" | "REJECTED" | "SYSTEM_WITHHELD" | "%future added value";
export type DecisionHistoryItemContainer_action = {
    readonly id: string;
    readonly comment: {
        readonly id: string;
        readonly author: {
            readonly username: string | null;
        } | null;
    } | null;
    readonly createdAt: string;
    readonly status: COMMENT_STATUS;
    readonly " $refType": "DecisionHistoryItemContainer_action";
};
export type DecisionHistoryItemContainer_action$data = DecisionHistoryItemContainer_action;
export type DecisionHistoryItemContainer_action$key = {
    readonly " $data"?: DecisionHistoryItemContainer_action$data;
    readonly " $fragmentRefs": FragmentRefs<"DecisionHistoryItemContainer_action">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "DecisionHistoryItemContainer_action",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "comment",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
            }
        ],
        "type": "CommentModerationAction",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '4fa40b7e02752b68e128b57252f0a9d2';
export default node;
