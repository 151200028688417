/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AccountCompletionContainer_auth = {
    readonly integrations: {
        readonly local: {
            readonly enabled: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
        };
    };
    readonly " $refType": "AccountCompletionContainer_auth";
};
export type AccountCompletionContainer_auth$data = AccountCompletionContainer_auth;
export type AccountCompletionContainer_auth$key = {
    readonly " $data"?: AccountCompletionContainer_auth$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountCompletionContainer_auth">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountCompletionContainer_auth",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocalAuthIntegration",
                    "kind": "LinkedField",
                    "name": "local",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticationTargetFilter",
                            "kind": "LinkedField",
                            "name": "targetFilter",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "admin",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "stream",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any);
(node as any).hash = '21b16ba7d78c676daf35658db6f3782a';
export default node;
