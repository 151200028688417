/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FlairBadgeConfigContainer_settings = {
    readonly flairBadges: {
        readonly flairBadgesEnabled: boolean | null;
        readonly badges: ReadonlyArray<{
            readonly name: string;
            readonly url: string;
        }>;
    } | null;
    readonly " $refType": "FlairBadgeConfigContainer_settings";
};
export type FlairBadgeConfigContainer_settings$data = FlairBadgeConfigContainer_settings;
export type FlairBadgeConfigContainer_settings$key = {
    readonly " $data"?: FlairBadgeConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"FlairBadgeConfigContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FlairBadgeConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "FlairBadgeConfiguration",
            "kind": "LinkedField",
            "name": "flairBadges",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flairBadgesEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlairBadge",
                    "kind": "LinkedField",
                    "name": "badges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'a224e9759e0e500ce70d22d6d53a1ca2';
export default node;
