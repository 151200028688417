/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserHistoryDrawerAllComments_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_viewer">;
    readonly " $refType": "UserHistoryDrawerAllComments_viewer";
};
export type UserHistoryDrawerAllComments_viewer$data = UserHistoryDrawerAllComments_viewer;
export type UserHistoryDrawerAllComments_viewer$key = {
    readonly " $data"?: UserHistoryDrawerAllComments_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserHistoryDrawerAllComments_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHistoryDrawerAllComments_viewer",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_viewer"
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = 'e99c6e4646037c1d4aa0f89d823114e5';
export default node;
