/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserRowContainer_settings = {
    readonly " $fragmentRefs": FragmentRefs<"UserStatusChangeContainer_settings" | "UserRoleChangeContainer_settings">;
    readonly " $refType": "UserRowContainer_settings";
};
export type UserRowContainer_settings$data = UserRowContainer_settings;
export type UserRowContainer_settings$key = {
    readonly " $data"?: UserRowContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"UserRowContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRowContainer_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserStatusChangeContainer_settings"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserRoleChangeContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'c57a30a670a142d1ee21c830071c7acd';
export default node;
