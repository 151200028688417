/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerationPhasesConfigContainer_settings = {
    readonly integrations: {
        readonly external: {
            readonly phases: ReadonlyArray<{
                readonly " $fragmentRefs": FragmentRefs<"ExternalModerationPhaseRow_phase">;
            }>;
        } | null;
    };
    readonly " $refType": "ModerationPhasesConfigContainer_settings";
};
export type ModerationPhasesConfigContainer_settings$data = ModerationPhasesConfigContainer_settings;
export type ModerationPhasesConfigContainer_settings$key = {
    readonly " $data"?: ModerationPhasesConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerationPhasesConfigContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerationPhasesConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ExternalIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomExternalIntegration",
                    "kind": "LinkedField",
                    "name": "external",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalModerationPhase",
                            "kind": "LinkedField",
                            "name": "phases",
                            "plural": true,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ExternalModerationPhaseRow_phase"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'd0b8b4eb9dbb89cd57d13d96fff4488e';
export default node;
