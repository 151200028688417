/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReactionConfigContainer_settings = {
    readonly reaction: {
        readonly icon: string;
        readonly iconActive: string | null;
    };
    readonly " $refType": "ReactionConfigContainer_settings";
};
export type ReactionConfigContainer_settings$data = ReactionConfigContainer_settings;
export type ReactionConfigContainer_settings$key = {
    readonly " $data"?: ReactionConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ReactionConfigContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReactionConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ReactionConfiguration",
            "kind": "LinkedField",
            "name": "reaction",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconActive",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'a76d6844165af51388ee6cf432fc28ab';
export default node;
