/* tslint:disable */
/* eslint-disable */
/* @relayHash 2f493dad28670745d368ea8efd904ed4 */

import { ConcreteRequest } from "relay-runtime";
export type CacheStoryInput = {
    id: string;
    clientMutationId: string;
};
export type RecacheStoryMutationVariables = {
    input: CacheStoryInput;
};
export type RecacheStoryMutationResponse = {
    readonly cacheStory: {
        readonly story: {
            readonly id: string;
        } | null;
        readonly clientMutationId: string;
    };
};
export type RecacheStoryMutation = {
    readonly response: RecacheStoryMutationResponse;
    readonly variables: RecacheStoryMutationVariables;
};



/*
mutation RecacheStoryMutation(
  $input: CacheStoryInput!
) {
  cacheStory(input: $input) {
    story {
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CacheStoryPayload",
            "kind": "LinkedField",
            "name": "cacheStory",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "story",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RecacheStoryMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RecacheStoryMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "2f493dad28670745d368ea8efd904ed4",
            "metadata": {},
            "name": "RecacheStoryMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'bbd9641f4e5d5bfe263d4e4e977f72b5';
export default node;
