/* tslint:disable */
/* eslint-disable */
/* @relayHash 0b18081d83849fb7e65821ae8f1fe556 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteAnnouncementInput = {
    clientMutationId: string;
};
export type DeleteAnnouncementMutationVariables = {
    input: DeleteAnnouncementInput;
};
export type DeleteAnnouncementMutationResponse = {
    readonly deleteAnnouncement: {
        readonly clientMutationId: string;
        readonly settings: {
            readonly announcement: {
                readonly content: string;
            } | null;
        };
    };
};
export type DeleteAnnouncementMutation = {
    readonly response: DeleteAnnouncementMutationResponse;
    readonly variables: DeleteAnnouncementMutationVariables;
};



/*
mutation DeleteAnnouncementMutation(
  $input: DeleteAnnouncementInput!
) {
  deleteAnnouncement(input: $input) {
    clientMutationId
    settings {
      announcement {
        content
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteAnnouncementMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteAnnouncementPayload",
                    "kind": "LinkedField",
                    "name": "deleteAnnouncement",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Announcement",
                                    "kind": "LinkedField",
                                    "name": "announcement",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteAnnouncementMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteAnnouncementPayload",
                    "kind": "LinkedField",
                    "name": "deleteAnnouncement",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Announcement",
                                    "kind": "LinkedField",
                                    "name": "announcement",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "0b18081d83849fb7e65821ae8f1fe556",
            "metadata": {},
            "name": "DeleteAnnouncementMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '78ca7669c572fc544b3e10fbc961dc61';
export default node;
