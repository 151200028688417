/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardContainer_query = {
    readonly sites: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly " $fragmentRefs": FragmentRefs<"DashboardSiteContainer_site">;
            };
        }>;
    };
    readonly viewer: {
        readonly moderationScopes: {
            readonly scoped: boolean;
            readonly sites: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly " $fragmentRefs": FragmentRefs<"DashboardSiteContainer_site">;
            }> | null;
        } | null;
    } | null;
    readonly " $refType": "DashboardContainer_query";
};
export type DashboardContainer_query$data = DashboardContainer_query;
export type DashboardContainer_query$key = {
    readonly " $data"?: DashboardContainer_query$data;
    readonly " $fragmentRefs": FragmentRefs<"DashboardContainer_query">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any), v2 = ({
        "args": null,
        "kind": "FragmentSpread",
        "name": "DashboardSiteContainer_site"
    } as any);
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 20,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": [
                        "sites"
                    ]
                }
            ]
        },
        "name": "DashboardContainer_query",
        "selections": [
            {
                "alias": "sites",
                "args": null,
                "concreteType": "SitesConnection",
                "kind": "LinkedField",
                "name": "__SitesConfig_sites_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Site",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    (v2 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserModerationScopes",
                        "kind": "LinkedField",
                        "name": "moderationScopes",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "scoped",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Site",
                                "kind": "LinkedField",
                                "name": "sites",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    (v2 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '5ebd7d428142c42b7426126b990d0172';
export default node;
