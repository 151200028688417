/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmailConfigContainer_email = {
    readonly enabled: boolean;
    readonly fromName: string | null;
    readonly fromEmail: string | null;
    readonly smtp: {
        readonly host: string | null;
        readonly port: number | null;
        readonly secure: boolean | null;
        readonly authentication: boolean | null;
        readonly username: string | null;
        readonly password: string | null;
    };
    readonly " $refType": "EmailConfigContainer_email";
};
export type EmailConfigContainer_email$data = EmailConfigContainer_email;
export type EmailConfigContainer_email$key = {
    readonly " $data"?: EmailConfigContainer_email$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailConfigContainer_email">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailConfigContainer_email",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fromName",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fromEmail",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "SMTP",
            "kind": "LinkedField",
            "name": "smtp",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "host",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "port",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "secure",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authentication",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "password",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "EmailConfiguration",
    "abstractKey": null
} as any);
(node as any).hash = '7be489e312039634145b9c750a095434';
export default node;
