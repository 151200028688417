/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BadgeConfig_formValues = {
    readonly badges: {
        readonly staffLabel: string;
        readonly adminLabel: string;
        readonly moderatorLabel: string;
        readonly memberLabel: string;
    };
    readonly " $refType": "BadgeConfig_formValues";
};
export type BadgeConfig_formValues$data = BadgeConfig_formValues;
export type BadgeConfig_formValues$key = {
    readonly " $data"?: BadgeConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"BadgeConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BadgeConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "BadgeConfiguration",
            "kind": "LinkedField",
            "name": "badges",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "staffLabel",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminLabel",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moderatorLabel",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "memberLabel",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '6f95c8988c031c24ec4935283a523259';
export default node;
