/* tslint:disable */
/* eslint-disable */
/* @relayHash b7ee0e7c77b6254f20585c419f899476 */

import { ConcreteRequest } from "relay-runtime";
export type NEW_USER_MODERATION = "BAN" | "PREMOD" | "%future added value";
export type UpdateEmailDomainRouteQueryVariables = {
    emailDomainID: string;
};
export type UpdateEmailDomainRouteQueryResponse = {
    readonly emailDomain: {
        readonly domain: string;
        readonly id: string;
        readonly newUserModeration: NEW_USER_MODERATION;
    } | null;
};
export type UpdateEmailDomainRouteQuery = {
    readonly response: UpdateEmailDomainRouteQueryResponse;
    readonly variables: UpdateEmailDomainRouteQueryVariables;
};



/*
query UpdateEmailDomainRouteQuery(
  $emailDomainID: ID!
) {
  emailDomain(id: $emailDomainID) {
    domain
    id
    newUserModeration
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "emailDomainID"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "emailDomainID"
                }
            ],
            "concreteType": "EmailDomain",
            "kind": "LinkedField",
            "name": "emailDomain",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domain",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "newUserModeration",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateEmailDomainRouteQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateEmailDomainRouteQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "b7ee0e7c77b6254f20585c419f899476",
            "metadata": {},
            "name": "UpdateEmailDomainRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '536b0c805c01fcca93bd7f0741ddfcfa';
export default node;
