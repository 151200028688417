/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GoogleConfig_formValues = {
    readonly integrations: {
        readonly google: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
            readonly clientID: string | null;
            readonly clientSecret: string | null;
        };
    };
    readonly " $refType": "GoogleConfig_formValues";
};
export type GoogleConfig_formValues$data = GoogleConfig_formValues;
export type GoogleConfig_formValues$key = {
    readonly " $data"?: GoogleConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"GoogleConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GoogleConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "GoogleAuthIntegration",
                    "kind": "LinkedField",
                    "name": "google",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowRegistration",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticationTargetFilter",
                            "kind": "LinkedField",
                            "name": "targetFilter",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "admin",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "stream",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "clientID",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "clientSecret",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any);
(node as any).hash = 'de79057bce323e35155e1c1917e12d56';
export default node;
