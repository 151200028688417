/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StoryCreationConfig_formValues = {
    readonly stories: {
        readonly scraping: {
            readonly enabled: boolean;
            readonly proxyURL: string | null;
            readonly customUserAgent: string | null;
            readonly authentication: boolean;
            readonly username: string | null;
            readonly password: string | null;
        };
        readonly disableLazy: boolean;
    };
    readonly " $refType": "StoryCreationConfig_formValues";
};
export type StoryCreationConfig_formValues$data = StoryCreationConfig_formValues;
export type StoryCreationConfig_formValues$key = {
    readonly " $data"?: StoryCreationConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"StoryCreationConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StoryCreationConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "StoryConfiguration",
            "kind": "LinkedField",
            "name": "stories",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "StoryScrapingConfiguration",
                    "kind": "LinkedField",
                    "name": "scraping",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "proxyURL",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "customUserAgent",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "authentication",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "password",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disableLazy",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '99a9bb0026f223d20b9ab4afba99992b';
export default node;
