/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrganizationURLConfig_formValues = {
    readonly organization: {
        readonly url: string;
    };
    readonly " $refType": "OrganizationURLConfig_formValues";
};
export type OrganizationURLConfig_formValues$data = OrganizationURLConfig_formValues;
export type OrganizationURLConfig_formValues$key = {
    readonly " $data"?: OrganizationURLConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"OrganizationURLConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationURLConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'a0424846d18a96cc1765d36a340729ae';
export default node;
