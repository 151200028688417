/* tslint:disable */
/* eslint-disable */
/* @relayHash 10850afeb6c5c8ff62087023626fd71a */

import { ConcreteRequest } from "relay-runtime";
export type CreateSiteInput = {
    clientMutationId: string;
    site: CreateSite;
};
export type CreateSite = {
    name: string;
    allowedOrigins: Array<string>;
};
export type CreateSiteMutationVariables = {
    input: CreateSiteInput;
};
export type CreateSiteMutationResponse = {
    readonly createSite: {
        readonly site: {
            readonly id: string;
            readonly name: string;
            readonly createdAt: string;
        };
        readonly clientMutationId: string;
    };
};
export type CreateSiteMutation = {
    readonly response: CreateSiteMutationResponse;
    readonly variables: CreateSiteMutationVariables;
};



/*
mutation CreateSiteMutation(
  $input: CreateSiteInput!
) {
  createSite(input: $input) {
    site {
      id
      name
      createdAt
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CreateSitePayload",
            "kind": "LinkedField",
            "name": "createSite",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateSiteMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateSiteMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "10850afeb6c5c8ff62087023626fd71a",
            "metadata": {},
            "name": "CreateSiteMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'afd216dc858c5190ad0a16482c919a96';
export default node;
