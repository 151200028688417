/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserMenuContainer_viewer = {
    readonly username: string | null;
    readonly " $refType": "UserMenuContainer_viewer";
};
export type UserMenuContainer_viewer$data = UserMenuContainer_viewer;
export type UserMenuContainer_viewer$key = {
    readonly " $data"?: UserMenuContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"UserMenuContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMenuContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = 'a3f7265fa7186f938d2d6063285be4dd';
export default node;
