/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PreModerationConfigContainer_settings = {
    readonly multisite: boolean;
    readonly " $refType": "PreModerationConfigContainer_settings";
};
export type PreModerationConfigContainer_settings$data = PreModerationConfigContainer_settings;
export type PreModerationConfigContainer_settings$key = {
    readonly " $data"?: PreModerationConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"PreModerationConfigContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreModerationConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multisite",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '1bcd7251cf0a8a48756a0b70eb664f1f';
export default node;
