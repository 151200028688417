/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MemberBioContainer_user = {
    readonly bio: string | null;
    readonly " $refType": "MemberBioContainer_user";
};
export type MemberBioContainer_user$data = MemberBioContainer_user;
export type MemberBioContainer_user$key = {
    readonly " $data"?: MemberBioContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"MemberBioContainer_user">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberBioContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '434a370f9a89cbac0154ce624425cfc2';
export default node;
