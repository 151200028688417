/* tslint:disable */
/* eslint-disable */
/* @relayHash 7a1d87d28656bc427ac8f2d209571758 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_SORT = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "REACTION_DESC" | "REPLIES_DESC" | "%future added value";
export type ConversationModalRepliesContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
    orderBy: COMMENT_SORT;
    commentID: string;
};
export type ConversationModalRepliesContainerPaginationQueryResponse = {
    readonly comment: {
        readonly " $fragmentRefs": FragmentRefs<"ConversationModalRepliesContainer_comment">;
    } | null;
};
export type ConversationModalRepliesContainerPaginationQuery = {
    readonly response: ConversationModalRepliesContainerPaginationQueryResponse;
    readonly variables: ConversationModalRepliesContainerPaginationQueryVariables;
};



/*
query ConversationModalRepliesContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $orderBy: COMMENT_SORT!
  $commentID: ID!
) {
  comment(id: $commentID) {
    ...ConversationModalRepliesContainer_comment_32czeo
    id
  }
}

fragment ConversationModalCommentContainer_comment on Comment {
  id
  body
  createdAt
  author {
    username
    id
  }
  revision {
    id
  }
  status
  replyCount
  parent {
    author {
      username
      id
    }
    id
  }
  ...MediaContainer_comment
}

fragment ConversationModalRepliesContainer_comment_32czeo on Comment {
  id
  replies(first: $count, after: $cursor, orderBy: $orderBy) {
    edges {
      node {
        id
        ...ConversationModalCommentContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  replyCount
}

fragment MediaContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TenorMedia {
        url
        title
        width
        height
        tenorStill: still
        tenorVideo: video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "commentID"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "orderBy"
    } as any), v4 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "commentID"
        } as any)
    ], v5 = ({
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v7 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        (v5 /*: any*/)
    ], v8 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            (v6 /*: any*/)
        ],
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v12 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
    } as any), v14 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v15 = [
        (v10 /*: any*/)
    ], v16 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "replyCount",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ConversationModalRepliesContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                },
                                (v5 /*: any*/)
                            ],
                            "kind": "FragmentSpread",
                            "name": "ConversationModalRepliesContainer_comment"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ConversationModalRepliesContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/),
                        {
                            "alias": null,
                            "args": (v7 /*: any*/),
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "replies",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "body",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "createdAt",
                                                    "storageKey": null
                                                },
                                                (v8 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "CommentRevision",
                                                    "kind": "LinkedField",
                                                    "name": "revision",
                                                    "plural": false,
                                                    "selections": [
                                                        (v6 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "media",
                                                            "plural": false,
                                                            "selections": [
                                                                (v9 /*: any*/),
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v10 /*: any*/),
                                                                        (v11 /*: any*/),
                                                                        (v12 /*: any*/),
                                                                        (v13 /*: any*/),
                                                                        (v14 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "video",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "GiphyMedia",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v10 /*: any*/),
                                                                        (v11 /*: any*/),
                                                                        (v12 /*: any*/),
                                                                        (v13 /*: any*/),
                                                                        {
                                                                            "alias": "tenorStill",
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "still",
                                                                            "storageKey": null
                                                                        },
                                                                        {
                                                                            "alias": "tenorVideo",
                                                                            "args": null,
                                                                            "kind": "ScalarField",
                                                                            "name": "video",
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "TenorMedia",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v15 /*: any*/),
                                                                    "type": "TwitterMedia",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        (v10 /*: any*/),
                                                                        (v14 /*: any*/),
                                                                        (v11 /*: any*/)
                                                                    ],
                                                                    "type": "YouTubeMedia",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": (v15 /*: any*/),
                                                                    "type": "ExternalMedia",
                                                                    "abstractKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "status",
                                                    "storageKey": null
                                                },
                                                (v16 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Comment",
                                                    "kind": "LinkedField",
                                                    "name": "parent",
                                                    "plural": false,
                                                    "selections": [
                                                        (v8 /*: any*/),
                                                        (v6 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Site",
                                                    "kind": "LinkedField",
                                                    "name": "site",
                                                    "plural": false,
                                                    "selections": [
                                                        (v6 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v7 /*: any*/),
                            "filters": [
                                "orderBy"
                            ],
                            "handle": "connection",
                            "key": "ConversationModalReplies_replies",
                            "kind": "LinkedHandle",
                            "name": "replies"
                        },
                        (v16 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "7a1d87d28656bc427ac8f2d209571758",
            "metadata": {},
            "name": "ConversationModalRepliesContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '706bfb2b19283ea5fda2164b8aa99309';
export default node;
