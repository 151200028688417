import { Localized } from "@fluent/react/compat";
import React, { FunctionComponent } from "react";

import { withStyles } from "coral-ui/hocs";

import styles from "./LogoHorizontal.css";

interface Props {
  className?: string;
  classes: typeof styles;
  fill?: string;
}

const LogoHorizontal: FunctionComponent<Props> = ({
  className,
  classes,
  fill,
  ...rest
}) => (
  <>
    <Localized id="ui-brandName">
      <h1 aria-hidden className={styles.hiddenTitle}>
        Coral
      </h1>
    </Localized>
    <svg
      id="Layer_1"
      className={styles.base}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1011.23 388.38"
    >
      <title>Coral by Vox Media</title>
      <path
        fill={fill}
        d="M384.61,375.13c0,6.54-4.55,10-11.82,10H359.44V350.25h12.22c7.27,0,11.81,3.24,11.81,9.49,0,3.75-2,6.47-4.54,7.38A8.45,8.45,0,0,1,384.61,375.13Zm-13-10.22c4,0,6.19-1.54,6.19-4.83s-2.16-4.72-6.19-4.72h-6.42v9.55ZM365.18,380h7.5c4,0,6.13-2,6.13-5.12s-2.15-5.11-6.13-5.11h-7.5Z"
      />
      <path
        fill={fill}
        d="M415.76,350.25H422l-12.67,21v13.92h-5.85V371.21l-12.62-21h6.42l9.26,15.62Z"
      />
      <path
        fill={fill}
        d="M358.84,148.31c0-56.33,44-100.89,99.76-100.89a98.22,98.22,0,0,1,75.95,35.31,12.88,12.88,0,0,1,3.36,8.41,11.9,11.9,0,0,1-12,11.77c-3.08,0-6.44-2-9.53-4.77-13.45-16.81-33.62-28-57.73-28-42,0-75.1,34.47-75.1,78.19,0,43.43,33.07,77.9,75.1,77.9,24.11,0,44.28-11.21,57.73-27.74,3.09-3.08,6.45-4.76,9.53-4.76a11.72,11.72,0,0,1,12,11.77,12.2,12.2,0,0,1-3.36,8.12,97.56,97.56,0,0,1-75.95,35.59C402.84,249.19,358.84,204.64,358.84,148.31Z"
      />
      <path
        fill={fill}
        d="M556.69,174.93c0-41.48,31.95-74.26,74-74.26s73.71,32.78,73.71,74.26-31.67,74.26-73.71,74.26S556.69,216.41,556.69,174.93Zm125,0c0-29.71-22.14-53.53-51-53.53s-51.28,23.82-51.28,53.53c0,29.42,22.42,53.53,51.28,53.53S681.68,204.35,681.68,174.93Z"
      />
      <path
        fill={fill}
        d="M729.87,161.2c0-36.15,23-60.53,57.73-60.53A10.34,10.34,0,0,1,798,111,10.52,10.52,0,0,1,787.6,121.4c-20.73,0-35.31,13.74-35.31,39.8v75.94a11.3,11.3,0,0,1-11.21,11.49,11.13,11.13,0,0,1-11.21-11.49Z"
      />
      <path
        fill={fill}
        d="M928.84,226.77a70.75,70.75,0,0,1-52.4,22.42c-41.2,0-72.59-32.78-72.59-74.26s31.39-74.26,72.59-74.26a71.84,71.84,0,0,1,52.4,22.41V112.43a11.25,11.25,0,0,1,11.21-11.21,11.07,11.07,0,0,1,11.21,11.21V237.14a11.13,11.13,0,0,1-11.21,11.49,11.31,11.31,0,0,1-11.21-11.49Zm0-49.6v-4.48c-1.12-29.15-23.26-51.29-51-51.29-28.59,0-51.29,23.26-51.29,53.53s22.7,53.53,51.29,53.53C905.58,228.46,927.72,206.32,928.84,177.17Z"
      />
      <path
        fill={fill}
        d="M988.81,59.19A11.06,11.06,0,0,1,1000,48a11.24,11.24,0,0,1,11.21,11.21v178A11.3,11.3,0,0,1,1000,248.63a11.13,11.13,0,0,1-11.21-11.49Z"
      />
      <path
        fill={fill}
        d="M279.85,193.46a9.61,9.61,0,0,0-12.58,5.1A129,129,0,0,1,157.9,277.07V253.94a37.3,37.3,0,0,1,1.77-3.66c6-10.78,17.19-16.92,30.15-24,14.75-8.1,31.47-17.28,41.68-35.46a9.6,9.6,0,0,0-16.74-9.4c-7.52,13.39-20.47,20.51-34.18,28-7.77,4.26-15.68,8.62-22.68,14.22V209.83c0-19.74,13.07-31,29.61-45.23,20.16-17.36,43-37,43-76.47a9.6,9.6,0,0,0-19.2,0c0,19.54-7.18,32.79-17.23,44-9.82-13.55-16.64-31.08-16.64-44a9.6,9.6,0,0,0-19.2,0c0,17.84,8.74,40.29,21.9,57.45L175,150.06c-9.75,8.39-19.67,16.95-26.67,27.64-7-10.69-16.93-19.25-26.68-27.64l-5.17-4.48c13.16-17.16,21.9-39.6,21.9-57.45a9.6,9.6,0,0,0-19.2,0c0,12.94-6.82,30.46-16.63,44-10.06-11.22-17.24-24.47-17.24-44a9.6,9.6,0,0,0-19.2,0c0,39.43,22.85,59.11,43,76.47,16.53,14.24,29.6,25.49,29.6,45.23v13.82c-7-5.61-14.92-10-22.7-14.23-13.71-7.53-26.66-14.65-34.18-28a9.59,9.59,0,0,0-16.73,9.4C75.3,209,92,218.14,106.77,226.24c13,7.12,24.16,13.26,30.15,24a37,37,0,0,1,1.79,3.7v23.09A129.07,129.07,0,0,1,57,57a129.19,129.19,0,0,1,210.26,41A9.6,9.6,0,0,0,285,90.58a148.38,148.38,0,0,0-273.29,0A148.3,148.3,0,0,0,253.17,253.17,147.66,147.66,0,0,0,285,206,9.59,9.59,0,0,0,279.85,193.46Z"
      />
      <path
        fill={fill}
        d="M529.25,340.63a23.88,23.88,0,1,0,23.88,23.88,23.88,23.88,0,0,0-23.88-23.88m0,35.71C523,376.34,518,371,518,364.51s5-11.83,11.26-11.83a11.84,11.84,0,0,1,0,23.66"
      />
      <polygon
        fill={fill}
        points="457.04 341.57 481.21 388.16 505.38 341.57 491.29 341.57 481.35 361.01 471.41 341.57 457.04 341.57"
      />
      <rect fill={fill} x="754.13" y="341.58" width="12.81" height="45.94" />
      <polygon
        fill={fill}
        points="699.3 353.08 699.3 341.57 664.08 341.57 664.08 387.51 699.3 387.51 699.3 376.01 677.48 376.01 677.48 370.08 697.69 370.08 697.69 359.01 677.48 359.01 677.48 353.08 699.3 353.08"
      />
      <path
        fill={fill}
        d="M725.05,341.57h-19.3v45.94h19.3a23,23,0,1,0,0-45.94m-1,34.3h-5.32V353.22H724a11.33,11.33,0,1,1,0,22.65"
      />
      <polygon
        fill={fill}
        points="643.67 361.9 643.67 387.51 656.62 387.51 656.62 341.57 640.58 341.57 630.64 360.73 620.7 341.57 604.65 341.57 604.65 387.51 617.32 387.51 617.32 361.9 630.5 387.3 643.67 361.9"
      />
      <polygon
        fill={fill}
        points="575.38 375.42 583.46 387.51 598.63 387.51 582.96 364.55 598.49 341.57 583.6 341.57 575.52 353.67 575.66 353.67 567.58 341.57 552.55 341.57 568.07 364.55 552.41 387.51 567.29 387.51 575.38 375.42"
      />
      <path
        fill={fill}
        d="M789.35,371.55l5.72-16.41,5.73,16.41Zm13.4-30H787.69l-16.56,45.94H784l2-5.91h18.21l2,5.91H819.3Z"
      />
      <path
        fill={fill}
        d="M384.61,375.13c0,6.54-4.55,10-11.82,10H359.44V350.25h12.22c7.27,0,11.81,3.24,11.81,9.49,0,3.75-2,6.47-4.54,7.38A8.45,8.45,0,0,1,384.61,375.13Zm-13-10.22c4,0,6.19-1.54,6.19-4.83s-2.16-4.72-6.19-4.72h-6.42v9.55ZM365.18,380h7.5c4,0,6.13-2,6.13-5.12s-2.15-5.11-6.13-5.11h-7.5Z"
      />
      <path
        fill={fill}
        d="M415.76,350.25H422l-12.67,21v13.92h-5.85V371.21l-12.62-21h6.42l9.26,15.62Z"
      />
      <path
        fill={fill}
        d="M529.25,340.63a23.88,23.88,0,1,0,23.88,23.88,23.88,23.88,0,0,0-23.88-23.88m0,35.71C523,376.34,518,371,518,364.51s5-11.83,11.26-11.83a11.84,11.84,0,0,1,0,23.66"
      />
      <polygon
        fill={fill}
        points="457.04 341.57 481.21 388.16 505.38 341.57 491.29 341.57 481.35 361.01 471.41 341.57 457.04 341.57"
      />
      <rect fill={fill} x="754.13" y="341.58" width="12.81" height="45.94" />
      <polygon
        fill={fill}
        points="699.3 353.08 699.3 341.57 664.08 341.57 664.08 387.51 699.3 387.51 699.3 376.01 677.48 376.01 677.48 370.08 697.69 370.08 697.69 359.01 677.48 359.01 677.48 353.08 699.3 353.08"
      />
      <path
        fill={fill}
        d="M725.05,341.57h-19.3v45.94h19.3a23,23,0,1,0,0-45.94m-1,34.3h-5.32V353.22H724a11.33,11.33,0,1,1,0,22.65"
      />
      <polygon
        fill={fill}
        points="643.67 361.9 643.67 387.51 656.62 387.51 656.62 341.57 640.58 341.57 630.64 360.73 620.7 341.57 604.65 341.57 604.65 387.51 617.32 387.51 617.32 361.9 630.5 387.3 643.67 361.9"
      />
      <polygon
        fill={fill}
        points="575.38 375.42 583.46 387.51 598.63 387.51 582.96 364.55 598.49 341.57 583.6 341.57 575.52 353.67 575.66 353.67 567.58 341.57 552.55 341.57 568.07 364.55 552.41 387.51 567.29 387.51 575.38 375.42"
      />
      <path
        fill={fill}
        d="M789.35,371.55l5.72-16.41,5.73,16.41Zm13.4-30H787.69l-16.56,45.94H784l2-5.91h18.21l2,5.91H819.3Z"
      />
    </svg>
  </>
);
LogoHorizontal.defaultProps = {
  fill: "#f77160",
};

export default withStyles(styles)(LogoHorizontal);
