/* tslint:disable */
/* eslint-disable */
/* @relayHash 432c334e4a1f465f511f08634be19844 */

import { ConcreteRequest } from "relay-runtime";
export type NEW_USER_MODERATION = "BAN" | "PREMOD" | "%future added value";
export type DeleteEmailDomainInput = {
    clientMutationId: string;
    id: string;
};
export type DeleteEmailDomainMutationVariables = {
    input: DeleteEmailDomainInput;
};
export type DeleteEmailDomainMutationResponse = {
    readonly deleteEmailDomain: {
        readonly settings: {
            readonly emailDomainModeration: ReadonlyArray<{
                readonly id: string;
                readonly domain: string;
                readonly newUserModeration: NEW_USER_MODERATION;
            }>;
        };
        readonly clientMutationId: string;
    };
};
export type DeleteEmailDomainMutation = {
    readonly response: DeleteEmailDomainMutationResponse;
    readonly variables: DeleteEmailDomainMutationVariables;
};



/*
mutation DeleteEmailDomainMutation(
  $input: DeleteEmailDomainInput!
) {
  deleteEmailDomain(input: $input) {
    settings {
      emailDomainModeration {
        id
        domain
        newUserModeration
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "concreteType": "EmailDomain",
        "kind": "LinkedField",
        "name": "emailDomainModeration",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domain",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newUserModeration",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteEmailDomainMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteEmailDomainPayload",
                    "kind": "LinkedField",
                    "name": "deleteEmailDomain",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteEmailDomainMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteEmailDomainPayload",
                    "kind": "LinkedField",
                    "name": "deleteEmailDomain",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "432c334e4a1f465f511f08634be19844",
            "metadata": {},
            "name": "DeleteEmailDomainMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '4ed233399e37d82b03090675d57f716a';
export default node;
