/* tslint:disable */
/* eslint-disable */
/* @relayHash d8e7388b145c71ef215832692db3144c */

import { ConcreteRequest } from "relay-runtime";
export type SiteSelectorCurrentSiteQueryVariables = {
    siteID: string;
};
export type SiteSelectorCurrentSiteQueryResponse = {
    readonly site: {
        readonly id: string;
        readonly name: string;
    } | null;
};
export type SiteSelectorCurrentSiteQuery = {
    readonly response: SiteSelectorCurrentSiteQueryResponse;
    readonly variables: SiteSelectorCurrentSiteQueryVariables;
};



/*
query SiteSelectorCurrentSiteQuery(
  $siteID: ID!
) {
  site(id: $siteID) {
    id
    name
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "siteID"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "siteID"
                }
            ],
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SiteSelectorCurrentSiteQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SiteSelectorCurrentSiteQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "d8e7388b145c71ef215832692db3144c",
            "metadata": {},
            "name": "SiteSelectorCurrentSiteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '03c60ab2167c9bb3596e815b55e369ce';
export default node;
