/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OIDCConfigContainer_auth = {
    readonly integrations: {
        readonly oidc: {
            readonly callbackURL: string;
        };
    };
    readonly " $refType": "OIDCConfigContainer_auth";
};
export type OIDCConfigContainer_auth$data = OIDCConfigContainer_auth;
export type OIDCConfigContainer_auth$key = {
    readonly " $data"?: OIDCConfigContainer_auth$data;
    readonly " $fragmentRefs": FragmentRefs<"OIDCConfigContainer_auth">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OIDCConfigContainer_auth",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "OIDCAuthIntegration",
                    "kind": "LinkedField",
                    "name": "oidc",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "callbackURL",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any);
(node as any).hash = '735bf0e717a11be3ca206f01784f8961';
export default node;
