/* tslint:disable */
/* eslint-disable */
/* @relayHash c924fcb8a0e95d9d0d41cd3d4cb088a8 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type COMMENT_BODY_FORMAT = "HTML" | "PLAIN_TEXT" | "%future added value";
export type UpdateExternalModerationPhaseInput = {
    clientMutationId: string;
    id: string;
    name?: string | null;
    url?: string | null;
    format?: COMMENT_BODY_FORMAT | null;
    timeout?: number | null;
};
export type UpdateExternalModerationPhaseMutationVariables = {
    input: UpdateExternalModerationPhaseInput;
};
export type UpdateExternalModerationPhaseMutationResponse = {
    readonly updateExternalModerationPhase: {
        readonly phase: {
            readonly " $fragmentRefs": FragmentRefs<"ConfigureExternalModerationPhaseContainer_phase">;
        };
    };
};
export type UpdateExternalModerationPhaseMutation = {
    readonly response: UpdateExternalModerationPhaseMutationResponse;
    readonly variables: UpdateExternalModerationPhaseMutationVariables;
};



/*
mutation UpdateExternalModerationPhaseMutation(
  $input: UpdateExternalModerationPhaseInput!
) {
  updateExternalModerationPhase(input: $input) {
    phase {
      ...ConfigureExternalModerationPhaseContainer_phase
      id
    }
  }
}

fragment ConfigureExternalModerationPhaseContainer_phase on ExternalModerationPhase {
  ...ExternalModerationPhaseDetails_phase
  ...ExternalModerationPhaseDangerZone_phase
  ...ExternalModerationPhaseStatus_phase
}

fragment ConfigureExternalModerationPhaseForm_phase on ExternalModerationPhase {
  id
  name
  url
  timeout
  format
}

fragment ExternalModerationPhaseDangerZone_phase on ExternalModerationPhase {
  id
  enabled
}

fragment ExternalModerationPhaseDetails_phase on ExternalModerationPhase {
  ...ConfigureExternalModerationPhaseForm_phase
}

fragment ExternalModerationPhaseStatus_phase on ExternalModerationPhase {
  id
  enabled
  signingSecret {
    secret
    createdAt
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateExternalModerationPhaseMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateExternalModerationPhasePayload",
                    "kind": "LinkedField",
                    "name": "updateExternalModerationPhase",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalModerationPhase",
                            "kind": "LinkedField",
                            "name": "phase",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ConfigureExternalModerationPhaseContainer_phase"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateExternalModerationPhaseMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateExternalModerationPhasePayload",
                    "kind": "LinkedField",
                    "name": "updateExternalModerationPhase",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalModerationPhase",
                            "kind": "LinkedField",
                            "name": "phase",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "timeout",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "format",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "enabled",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SigningSecret",
                                    "kind": "LinkedField",
                                    "name": "signingSecret",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "secret",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "c924fcb8a0e95d9d0d41cd3d4cb088a8",
            "metadata": {},
            "name": "UpdateExternalModerationPhaseMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'e2b04cd34818eb57bc1086d3ece562f4';
export default node;
