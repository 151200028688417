"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const long_settimeout_1 = require("long-settimeout");
function createTimeoutAt(callback, timeoutAt) {
    const diff = new Date(timeoutAt).valueOf() - Date.now();
    if (diff > 0) {
        return long_settimeout_1.setLongTimeout(callback, diff);
    }
    return null;
}
exports.default = createTimeoutAt;
