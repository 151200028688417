/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserRowContainer_user = {
    readonly id: string;
    readonly username: string | null;
    readonly email: string | null;
    readonly createdAt: string;
    readonly deletedAt: string | null;
    readonly " $fragmentRefs": FragmentRefs<"UserStatusChangeContainer_user" | "UserRoleChangeContainer_user">;
    readonly " $refType": "UserRowContainer_user";
};
export type UserRowContainer_user$data = UserRowContainer_user;
export type UserRowContainer_user$key = {
    readonly " $data"?: UserRowContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserRowContainer_user">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRowContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deletedAt",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserStatusChangeContainer_user"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserRoleChangeContainer_user"
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '59b2c5233c4e95c6c849c1eefb6aff22';
export default node;
