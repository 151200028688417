/* tslint:disable */
/* eslint-disable */
/* @relayHash 3c0b0a4c2bc9f219107c608f3617e957 */

import { ConcreteRequest } from "relay-runtime";
export type FetchStoryCachedQueryVariables = {
    storyID: string;
};
export type FetchStoryCachedQueryResponse = {
    readonly story: {
        readonly id: string;
        readonly cached: boolean | null;
    } | null;
};
export type FetchStoryCachedQuery = {
    readonly response: FetchStoryCachedQueryResponse;
    readonly variables: FetchStoryCachedQueryVariables;
};



/*
query FetchStoryCachedQuery(
  $storyID: ID!
) {
  story(id: $storyID) {
    id
    cached
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "storyID"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "storyID"
                }
            ],
            "concreteType": "Story",
            "kind": "LinkedField",
            "name": "story",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cached",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "FetchStoryCachedQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "FetchStoryCachedQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "3c0b0a4c2bc9f219107c608f3617e957",
            "metadata": {},
            "name": "FetchStoryCachedQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '762bd5ecd0aaab8c55e2309aa52d5c4a';
export default node;
