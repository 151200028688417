/* tslint:disable */
/* eslint-disable */
/* @relayHash 950f5c15e6a9f8ba16a28d7fc387206c */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteSelectorContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
    searchFilter?: string | null;
};
export type SiteSelectorContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"SiteSelectorContainer_query">;
};
export type SiteSelectorContainerPaginationQuery = {
    readonly response: SiteSelectorContainerPaginationQueryResponse;
    readonly variables: SiteSelectorContainerPaginationQueryVariables;
};



/*
query SiteSelectorContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $searchFilter: String
) {
  ...SiteSelectorContainer_query_aN7PH
}

fragment SiteSelectorContainer_query_aN7PH on Query {
  sites(first: $count, after: $cursor, query: $searchFilter) {
    edges {
      node {
        id
        ...SiteSelectorSite_site
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment SiteSelectorSite_site on Site {
  name
  id
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
        } as any),
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any),
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "searchFilter"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        ({
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SiteSelectorContainerPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SiteSelectorContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SiteSelectorContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SitesConnection",
                    "kind": "LinkedField",
                    "name": "sites",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SiteEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "filters": [
                        "query"
                    ],
                    "handle": "connection",
                    "key": "SitesConfig_sites",
                    "kind": "LinkedHandle",
                    "name": "sites"
                }
            ]
        },
        "params": {
            "id": "950f5c15e6a9f8ba16a28d7fc387206c",
            "metadata": {},
            "name": "SiteSelectorContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'ac757cb25c24db607f4fd672e03c3829';
export default node;
