/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmbeddedCommentsConfig_formValues = {
    readonly embeddedComments: {
        readonly allowReplies: boolean | null;
        readonly oEmbedAllowedOrigins: ReadonlyArray<string>;
    } | null;
    readonly " $refType": "EmbeddedCommentsConfig_formValues";
};
export type EmbeddedCommentsConfig_formValues$data = EmbeddedCommentsConfig_formValues;
export type EmbeddedCommentsConfig_formValues$key = {
    readonly " $data"?: EmbeddedCommentsConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"EmbeddedCommentsConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmbeddedCommentsConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "EmbeddedCommentsConfiguration",
            "kind": "LinkedField",
            "name": "embeddedComments",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowReplies",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "oEmbedAllowedOrigins",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'ce85abacdd3ea03ae33b1226904ac1d1';
export default node;
