"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * This function waits given number of ms and
 * requests an idle frame to call the callback.
 */
function waitAndRequestIdleCallback(callback, wait) {
    const supportsIdleCallbacks = window.requestIdleCallback && window.cancelIdleCallback;
    let idleCallbackHandle = 0;
    const timeout = setTimeout(() => {
        if (supportsIdleCallbacks) {
            idleCallbackHandle = window.requestIdleCallback(callback);
            return;
        }
        callback();
    }, wait);
    return () => {
        clearTimeout(timeout);
        if (supportsIdleCallbacks) {
            window.cancelIdleCallback(idleCallbackHandle);
        }
    };
}
exports.default = waitAndRequestIdleCallback;
