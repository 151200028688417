/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmailDomainConfigContainer_settings = {
    readonly protectedEmailDomains: ReadonlyArray<string>;
    readonly " $fragmentRefs": FragmentRefs<"EmailDomainTableContainer_settings">;
    readonly " $refType": "EmailDomainConfigContainer_settings";
};
export type EmailDomainConfigContainer_settings$data = EmailDomainConfigContainer_settings;
export type EmailDomainConfigContainer_settings$key = {
    readonly " $data"?: EmailDomainConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailDomainConfigContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailDomainConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "protectedEmailDomains",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EmailDomainTableContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '706715cf263135e47b0480e10eb474e2';
export default node;
