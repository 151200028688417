/* tslint:disable */
/* eslint-disable */
/* @relayHash 7b764c8c2f09c1a2cdba1305e307c2be */

import { ConcreteRequest } from "relay-runtime";
export type DiscoverOIDCConfigurationFetchQueryVariables = {
    issuer: string;
};
export type DiscoverOIDCConfigurationFetchQueryResponse = {
    readonly discoverOIDCConfiguration: {
        readonly issuer: string;
        readonly authorizationURL: string;
        readonly tokenURL: string | null;
        readonly jwksURI: string;
    } | null;
};
export type DiscoverOIDCConfigurationFetchQuery = {
    readonly response: DiscoverOIDCConfigurationFetchQueryResponse;
    readonly variables: DiscoverOIDCConfigurationFetchQueryVariables;
};



/*
query DiscoverOIDCConfigurationFetchQuery(
  $issuer: String!
) {
  discoverOIDCConfiguration(issuer: $issuer) {
    issuer
    authorizationURL
    tokenURL
    jwksURI
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "issuer"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "issuer",
                    "variableName": "issuer"
                }
            ],
            "concreteType": "DiscoveredOIDCConfiguration",
            "kind": "LinkedField",
            "name": "discoverOIDCConfiguration",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issuer",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authorizationURL",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tokenURL",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "jwksURI",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DiscoverOIDCConfigurationFetchQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DiscoverOIDCConfigurationFetchQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "7b764c8c2f09c1a2cdba1305e307c2be",
            "metadata": {},
            "name": "DiscoverOIDCConfigurationFetchQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '29dbfe522d0835d3fd12eb81455de0f5';
export default node;
