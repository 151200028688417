"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.predicates = void 0;
const types_1 = require("./types");
const usersCantUpdateSelves = ({ viewer, user, }) => ({
    pass: viewer.id !== user.id,
    reason: "Users cannot update their own roles or scopes",
});
const onlyAdminsCanAllocateStaff = ({ viewer, user, newUserRole, }) => ({
    pass: !(viewer.role !== "ADMIN" && newUserRole === "STAFF"),
    reason: "Only admins may allocate staff",
});
const onlyAdminsCanDemoteStaffDirectly = ({ viewer, user, }) => ({
    pass: !(viewer.role !== "ADMIN" && user.role === "STAFF"),
    reason: "Only admins may change staffs roles",
});
/**
 * This (terribly named) predicate ensures that a scoped viewer cannot
 * change a scoped user's role in a way that inadvertantly
 * remove scopes the viewer does not posses.
 */
const scopedUsersCantIndirectlyRemoveScopesTheyDontPosses = ({ viewer, user, newUserRole, scoped }) => {
    var _a;
    const reason = "This role change would remove scopes outside of the viewers authorization";
    const changingAwayFromCurrentScopedRole = (types_1.isSiteModerator(user) || types_1.isSiteMember(user)) && !scoped;
    if (viewer.role === "ADMIN" ||
        types_1.isOrgModerator(viewer) ||
        !changingAwayFromCurrentScopedRole) {
        return {
            pass: true,
            reason,
        };
    }
    const relevantScopes = user.role === "MODERATOR" ? user.moderationScopes : user.membershipScopes;
    const userHasScopesViewerDoesnt = !!((_a = relevantScopes === null || relevantScopes === void 0 ? void 0 : relevantScopes.siteIDs) === null || _a === void 0 ? void 0 : _a.find((userSiteID) => { var _a, _b; return !((_b = (_a = viewer.moderationScopes) === null || _a === void 0 ? void 0 : _a.siteIDs) === null || _b === void 0 ? void 0 : _b.includes(userSiteID)); }));
    return {
        pass: !userHasScopesViewerDoesnt,
        reason,
    };
};
exports.predicates = [
    usersCantUpdateSelves,
    onlyAdminsCanAllocateStaff,
    onlyAdminsCanDemoteStaffDirectly,
    scopedUsersCantIndirectlyRemoveScopesTheyDontPosses,
];
