/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UnmoderatedCountsConfig_formValues = {
    readonly showUnmoderatedCounts: boolean | null;
    readonly " $refType": "UnmoderatedCountsConfig_formValues";
};
export type UnmoderatedCountsConfig_formValues$data = UnmoderatedCountsConfig_formValues;
export type UnmoderatedCountsConfig_formValues$key = {
    readonly " $data"?: UnmoderatedCountsConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"UnmoderatedCountsConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnmoderatedCountsConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "showUnmoderatedCounts",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '442dc2d873f65b2d91fe4fa3d01bbb60';
export default node;
