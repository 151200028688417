/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReportMakeDecisionModal_dsaReport = {
    readonly id: string;
    readonly comment: {
        readonly id: string;
        readonly revision: {
            readonly id: string;
        } | null;
    } | null;
    readonly " $refType": "ReportMakeDecisionModal_dsaReport";
};
export type ReportMakeDecisionModal_dsaReport$data = ReportMakeDecisionModal_dsaReport;
export type ReportMakeDecisionModal_dsaReport$key = {
    readonly " $data"?: ReportMakeDecisionModal_dsaReport$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportMakeDecisionModal_dsaReport">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ReportMakeDecisionModal_dsaReport",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "comment",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentRevision",
                        "kind": "LinkedField",
                        "name": "revision",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "DSAReport",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'd27672f3b5e7002d90d32ae94d110adc';
export default node;
