"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCALES = exports.LOCALES_MAP = void 0;
/**
 * LOCALES_MAP contains a map of language codes associated with their
 * name in native language.
 */
exports.LOCALES_MAP = {
    "af-ZA": "Afrikaans",
    "ar-AE": "عربى",
    "en-US": "English",
    "pt-BR": "Português brasileiro",
    es: "Español",
    de: "Deutsch",
    "tr-TR": "Türkçe",
    hu: "Magyar",
    "id-ID": "Indonesian",
    "it-IT": "Italiana",
    "ja-JP": "日本",
    "de-CH": "Deutsch-Schweiz",
    "nl-NL": "Nederlands",
    da: "Dansk",
    "fr-FR": "Francais",
    ro: "Română",
    "fi-FI": "Suomi",
    sv: "Svenska",
    pl: "Polski",
    "sk-SK": "Slovensky",
    ru: "Русский",
    "nb-NO": "Norsk Bokmål",
    "zh-CN": "中国人",
};
/**
 * LOCALES is an array of supported language codes that can be accessed as a
 * value.
 */
exports.LOCALES = Object.keys(exports.LOCALES_MAP);
