/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentEditingConfig_formValues = {
    readonly editCommentWindowLength: number;
    readonly " $refType": "CommentEditingConfig_formValues";
};
export type CommentEditingConfig_formValues$data = CommentEditingConfig_formValues;
export type CommentEditingConfig_formValues$key = {
    readonly " $data"?: CommentEditingConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentEditingConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentEditingConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "editCommentWindowLength",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '398f915791fb7825278edba34c713c10';
export default node;
