/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConfigureWebhookEndpointForm_settings = {
    readonly " $fragmentRefs": FragmentRefs<"EventsSelectField_settings">;
    readonly " $refType": "ConfigureWebhookEndpointForm_settings";
};
export type ConfigureWebhookEndpointForm_settings$data = ConfigureWebhookEndpointForm_settings;
export type ConfigureWebhookEndpointForm_settings$key = {
    readonly " $data"?: ConfigureWebhookEndpointForm_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointForm_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureWebhookEndpointForm_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventsSelectField_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '96e61c5c84523d77236a3c63626a2beb';
export default node;
