/* tslint:disable */
/* eslint-disable */
/* @relayHash 27e9bc0e4288df40159cdb82a19d7638 */

import { ConcreteRequest } from "relay-runtime";
export type AddSiteRouteQueryVariables = {};
export type AddSiteRouteQueryResponse = {
    readonly settings: {
        readonly organization: {
            readonly name: string;
        };
    };
};
export type AddSiteRouteQuery = {
    readonly response: AddSiteRouteQueryResponse;
    readonly variables: AddSiteRouteQueryVariables;
};



/*
query AddSiteRouteQuery {
  settings {
    organization {
      name
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AddSiteRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AddSiteRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "27e9bc0e4288df40159cdb82a19d7638",
            "metadata": {},
            "name": "AddSiteRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '24bbf7bb122ad3b0bbff973c92d3090d';
export default node;
