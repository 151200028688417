/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "SPECIFIC_SITES_PRE" | "%future added value";
export type PreModerationConfigContainer_formValues = {
    readonly moderation: MODERATION_MODE | null;
    readonly premodLinksEnable: boolean | null;
    readonly premoderateSuspectWords: boolean;
    readonly premoderateAllCommentsSites: ReadonlyArray<string>;
    readonly " $refType": "PreModerationConfigContainer_formValues";
};
export type PreModerationConfigContainer_formValues$data = PreModerationConfigContainer_formValues;
export type PreModerationConfigContainer_formValues$key = {
    readonly " $data"?: PreModerationConfigContainer_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"PreModerationConfigContainer_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreModerationConfigContainer_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "moderation",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premodLinksEnable",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premoderateSuspectWords",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premoderateAllCommentsSites",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '6adc5f40dbddaff8571562107fb61d71';
export default node;
