/* tslint:disable */
/* eslint-disable */
/* @relayHash c06dd6b5b6fc991563f37b247f987cdc */

import { ConcreteRequest } from "relay-runtime";
export type InvalidateCachedStoryInput = {
    id: string;
    clientMutationId: string;
};
export type InvalidateCachedStoryMutationVariables = {
    input: InvalidateCachedStoryInput;
};
export type InvalidateCachedStoryMutationResponse = {
    readonly invalidateCachedStory: {
        readonly story: {
            readonly cached: boolean | null;
            readonly id: string;
        } | null;
        readonly clientMutationId: string;
    };
};
export type InvalidateCachedStoryMutation = {
    readonly response: InvalidateCachedStoryMutationResponse;
    readonly variables: InvalidateCachedStoryMutationVariables;
};



/*
mutation InvalidateCachedStoryMutation(
  $input: InvalidateCachedStoryInput!
) {
  invalidateCachedStory(input: $input) {
    story {
      cached
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "InvalidateCachedStoryPayload",
            "kind": "LinkedField",
            "name": "invalidateCachedStory",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "story",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cached",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "InvalidateCachedStoryMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "InvalidateCachedStoryMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "c06dd6b5b6fc991563f37b247f987cdc",
            "metadata": {},
            "name": "InvalidateCachedStoryMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '02474af6b4fa533bebb48cc0470c2887';
export default node;
