import cn from "classnames";
import React, { FunctionComponent } from "react";

import { withStyles } from "coral-ui/hocs";

import styles from "./BrandMark.css";

interface Props {
  className?: string;
  classes: typeof styles;
  size?: "md" | "lg";
  fill?: string;
}

const BrandMark: FunctionComponent<Props> = ({
  className,
  classes,
  size,
  fill,
  ...rest
}) => (
  <svg
    {...rest}
    className={cn(classes.base, className, {
      [classes.md]: size === "md",
      [classes.lg]: size === "lg",
    })}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 520.96 540.83"
  >
    <path
      fill={fill}
      d="M510.27,352.74a17.5,17.5,0,0,0-22.93,9.3A235.18,235.18,0,0,1,287.92,505.19V463a66.65,66.65,0,0,1,3.22-6.65c10.93-19.65,31.34-30.85,55-43.83,26.9-14.77,57.38-31.51,76-64.65a17.5,17.5,0,1,0-30.51-17.14c-13.72,24.41-37.33,37.38-62.33,51.11-14.16,7.78-28.59,15.71-41.34,25.91V382.59c0-36,23.82-56.49,54-82.46,18.33-15.79,37.29-32.11,52.27-53.74C411.77,221,420.32,193,420.32,160.7a17.5,17.5,0,0,0-35,0c0,35.62-13.1,59.79-31.43,80.24-17.9-24.71-30.34-56.66-30.34-80.24a17.5,17.5,0,0,0-35,0c0,32.53,15.93,73.46,39.93,104.75-3.11,2.72-6.26,5.43-9.43,8.16-17.77,15.31-35.87,30.91-48.63,50.41-12.77-19.5-30.86-35.1-48.64-50.41-3.17-2.73-6.32-5.44-9.42-8.16,24-31.29,39.92-72.22,39.92-104.75a17.5,17.5,0,0,0-35,0c0,23.58-12.44,55.54-30.34,80.24-18.33-20.45-31.43-44.62-31.43-80.24a17.5,17.5,0,0,0-35,0c0,32.26,8.56,60.29,26.16,85.69,15,21.63,33.94,37.95,52.27,53.74,30.16,26,54,46.48,54,82.46v25.19c-12.77-10.22-27.21-18.16-41.38-25.94-25-13.73-48.62-26.7-62.33-51.11a17.5,17.5,0,1,0-30.51,17.14c18.61,33.14,49.09,49.88,76,64.65,23.63,13,44,24.18,55,43.83a70.43,70.43,0,0,1,3.26,6.72v42.12A235.34,235.34,0,0,1,104,104a235.56,235.56,0,0,1,383.39,74.84,17.5,17.5,0,1,0,32.23-13.64,270.22,270.22,0,1,0,0,210.53A17.5,17.5,0,0,0,510.27,352.74Z"
    />
  </svg>
);
BrandMark.defaultProps = {
  size: "md",
  fill: "#f77160",
};

export default withStyles(styles)(BrandMark);
