/* tslint:disable */
/* eslint-disable */
/* @relayHash 97c8789189942df2aac2e123aa487d0a */

import { ConcreteRequest } from "relay-runtime";
export type STORY_STATUS = "CLOSED" | "OPEN" | "%future added value";
export type ArchiveStoriesInput = {
    clientMutationId: string;
    storyIDs: Array<string>;
};
export type ArchiveStoriesMutationVariables = {
    input: ArchiveStoriesInput;
};
export type ArchiveStoriesMutationResponse = {
    readonly archiveStories: {
        readonly stories: ReadonlyArray<{
            readonly id: string;
            readonly status: STORY_STATUS;
            readonly isArchived: boolean;
            readonly isArchiving: boolean;
        }>;
        readonly clientMutationId: string;
    };
};
export type ArchiveStoriesMutation = {
    readonly response: ArchiveStoriesMutationResponse;
    readonly variables: ArchiveStoriesMutationVariables;
};



/*
mutation ArchiveStoriesMutation(
  $input: ArchiveStoriesInput!
) {
  archiveStories(input: $input) {
    stories {
      id
      status
      isArchived
      isArchiving
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ArchiveStoriesPayload",
            "kind": "LinkedField",
            "name": "archiveStories",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "stories",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchived",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchiving",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ArchiveStoriesMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ArchiveStoriesMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "97c8789189942df2aac2e123aa487d0a",
            "metadata": {},
            "name": "ArchiveStoriesMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '64656374106a9406bb281a4ade4049a5';
export default node;
