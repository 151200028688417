/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "SPECIFIC_SITES_PRE" | "%future added value";
export type NewCommentersConfigContainer_formValues = {
    readonly newCommenters: {
        readonly premodEnabled: boolean;
        readonly approvedCommentsThreshold: number;
        readonly moderation: {
            readonly mode: MODERATION_MODE | null;
            readonly premodSites: ReadonlyArray<string>;
        } | null;
    };
    readonly " $refType": "NewCommentersConfigContainer_formValues";
};
export type NewCommentersConfigContainer_formValues$data = NewCommentersConfigContainer_formValues;
export type NewCommentersConfigContainer_formValues$key = {
    readonly " $data"?: NewCommentersConfigContainer_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"NewCommentersConfigContainer_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewCommentersConfigContainer_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "NewCommentersConfiguration",
            "kind": "LinkedField",
            "name": "newCommenters",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "premodEnabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "approvedCommentsThreshold",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "NewCommentersModerationConfig",
                    "kind": "LinkedField",
                    "name": "moderation",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mode",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "premodSites",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '99bfa228e922cbe5fa88002d60df4059';
export default node;
