"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLTESiteModerator = exports.isLTModerator = exports.isLTEModerator = exports.isOrgModerator = exports.isSiteModerator = exports.isModerator = exports.isSiteMember = void 0;
const isSiteModerationScoped_1 = require("./isSiteModerationScoped");
exports.isSiteMember = (user) => {
    var _a, _b, _c;
    return (user.role === "MEMBER" &&
        (!!((_a = user.membershipScopes) === null || _a === void 0 ? void 0 : _a.scoped) ||
            !!((_c = (_b = user.membershipScopes) === null || _b === void 0 ? void 0 : _b.siteIDs) === null || _c === void 0 ? void 0 : _c.length)));
};
exports.isModerator = (user) => {
    return user.role === "MODERATOR";
};
exports.isSiteModerator = (user) => user.role === "MODERATOR" && isSiteModerationScoped_1.isSiteModerationScoped(user.moderationScopes);
exports.isOrgModerator = (user) => user.role === "MODERATOR" && !isSiteModerationScoped_1.isSiteModerationScoped(user.moderationScopes);
exports.isLTEModerator = (user) => {
    return user.role !== "ADMIN";
};
exports.isLTModerator = (user) => user.role === "COMMENTER" || user.role === "MEMBER" || user.role === "STAFF";
exports.isLTESiteModerator = (user) => exports.isLTModerator(user) ||
    (exports.isModerator(user) && isSiteModerationScoped_1.isSiteModerationScoped(user.moderationScopes));
