/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FlairBadgeConfigContainer_formValues = {
    readonly flairBadges: {
        readonly flairBadgesEnabled: boolean | null;
    } | null;
    readonly " $refType": "FlairBadgeConfigContainer_formValues";
};
export type FlairBadgeConfigContainer_formValues$data = FlairBadgeConfigContainer_formValues;
export type FlairBadgeConfigContainer_formValues$key = {
    readonly " $data"?: FlairBadgeConfigContainer_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"FlairBadgeConfigContainer_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FlairBadgeConfigContainer_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "FlairBadgeConfiguration",
            "kind": "LinkedField",
            "name": "flairBadges",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flairBadgesEnabled",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'ebd85e6102fbab6f72e768ca0708d46b';
export default node;
