/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkAccountContainer_viewer = {
    readonly duplicateEmail: string | null;
    readonly " $refType": "LinkAccountContainer_viewer";
};
export type LinkAccountContainer_viewer$data = LinkAccountContainer_viewer;
export type LinkAccountContainer_viewer$key = {
    readonly " $data"?: LinkAccountContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"LinkAccountContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkAccountContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duplicateEmail",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '9aec85daac0e4102518770c12ea10eba';
export default node;
