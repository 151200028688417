/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DSAReportStatus = "AWAITING_REVIEW" | "COMPLETED" | "UNDER_REVIEW" | "VOID" | "%future added value";
export type ReportShareButton_dsaReport = {
    readonly id: string;
    readonly referenceID: string;
    readonly status: DSAReportStatus | null;
    readonly " $refType": "ReportShareButton_dsaReport";
};
export type ReportShareButton_dsaReport$data = ReportShareButton_dsaReport;
export type ReportShareButton_dsaReport$key = {
    readonly " $data"?: ReportShareButton_dsaReport$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportShareButton_dsaReport">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportShareButton_dsaReport",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referenceID",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
        }
    ],
    "type": "DSAReport",
    "abstractKey": null
} as any);
(node as any).hash = 'a3e392977146c49794281e4cdca60004';
export default node;
