/* tslint:disable */
/* eslint-disable */
/* @relayHash 88c9ecdd971106e9f3d6d7c75184f52b */

import { ConcreteRequest } from "relay-runtime";
export type UserDeletionUpdateType = "CANCELED" | "REQUESTED" | "%future added value";
export type ScheduleAccountDeletionInput = {
    userID: string;
    clientMutationId: string;
};
export type ScheduleAccountDeletionMutationVariables = {
    input: ScheduleAccountDeletionInput;
};
export type ScheduleAccountDeletionMutationResponse = {
    readonly scheduleAccountDeletion: {
        readonly user: {
            readonly scheduledDeletionDate: string | null;
            readonly status: {
                readonly deletion: {
                    readonly history: ReadonlyArray<{
                        readonly updateType: UserDeletionUpdateType;
                        readonly createdBy: {
                            readonly username: string | null;
                        } | null;
                        readonly createdAt: string;
                    }>;
                };
            };
        } | null;
        readonly clientMutationId: string;
    };
};
export type ScheduleAccountDeletionMutation = {
    readonly response: ScheduleAccountDeletionMutationResponse;
    readonly variables: ScheduleAccountDeletionMutationVariables;
};



/*
mutation ScheduleAccountDeletionMutation(
  $input: ScheduleAccountDeletionInput!
) {
  scheduleAccountDeletion(input: $input) {
    user {
      scheduledDeletionDate
      status {
        deletion {
          history {
            updateType
            createdBy {
              username
              id
            }
            createdAt
          }
        }
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scheduledDeletionDate",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updateType",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ScheduleAccountDeletionMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleAccountDeletionPayload",
                    "kind": "LinkedField",
                    "name": "scheduleAccountDeletion",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserStatus",
                                    "kind": "LinkedField",
                                    "name": "status",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserDeletionStatus",
                                            "kind": "LinkedField",
                                            "name": "deletion",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "UserDeletionHistory",
                                                    "kind": "LinkedField",
                                                    "name": "history",
                                                    "plural": true,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "createdBy",
                                                            "plural": false,
                                                            "selections": [
                                                                (v4 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ScheduleAccountDeletionMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ScheduleAccountDeletionPayload",
                    "kind": "LinkedField",
                    "name": "scheduleAccountDeletion",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserStatus",
                                    "kind": "LinkedField",
                                    "name": "status",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserDeletionStatus",
                                            "kind": "LinkedField",
                                            "name": "deletion",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "UserDeletionHistory",
                                                    "kind": "LinkedField",
                                                    "name": "history",
                                                    "plural": true,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "User",
                                                            "kind": "LinkedField",
                                                            "name": "createdBy",
                                                            "plural": false,
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "88c9ecdd971106e9f3d6d7c75184f52b",
            "metadata": {},
            "name": "ScheduleAccountDeletionMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'ad25866995984341be6d253ac7edab39';
export default node;
