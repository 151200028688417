/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type ArchiveStoryActionsContainer_viewer = {
    readonly role: USER_ROLE;
    readonly " $refType": "ArchiveStoryActionsContainer_viewer";
};
export type ArchiveStoryActionsContainer_viewer$data = ArchiveStoryActionsContainer_viewer;
export type ArchiveStoryActionsContainer_viewer$key = {
    readonly " $data"?: ArchiveStoryActionsContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ArchiveStoryActionsContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ArchiveStoryActionsContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = 'f4ccdac6b2af7069b1b656bb74b4cd21';
export default node;
