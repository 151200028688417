/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SlackConfigContainer_settings = {
    readonly slack: {
        readonly channels: ReadonlyArray<{
            readonly enabled: boolean;
            readonly name: string | null;
            readonly hookURL: string | null;
            readonly triggers: {
                readonly reportedComments: boolean | null;
                readonly pendingComments: boolean | null;
                readonly featuredComments: boolean | null;
                readonly allComments: boolean | null;
                readonly staffComments: boolean | null;
            };
        }>;
    };
    readonly " $refType": "SlackConfigContainer_settings";
};
export type SlackConfigContainer_settings$data = SlackConfigContainer_settings;
export type SlackConfigContainer_settings$key = {
    readonly " $data"?: SlackConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"SlackConfigContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SlackConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "SlackConfiguration",
            "kind": "LinkedField",
            "name": "slack",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SlackChannel",
                    "kind": "LinkedField",
                    "name": "channels",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hookURL",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SlackChannelTriggers",
                            "kind": "LinkedField",
                            "name": "triggers",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "reportedComments",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "pendingComments",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "featuredComments",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "allComments",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "staffComments",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'b34a3b1545cab5b939c33eee886dd3ac';
export default node;
