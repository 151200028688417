/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type InviteUsersContainer_settings = {
    readonly email: {
        readonly enabled: boolean;
    };
    readonly auth: {
        readonly integrations: {
            readonly local: {
                readonly enabled: boolean;
                readonly allowRegistration: boolean;
                readonly targetFilter: {
                    readonly admin: boolean;
                };
            };
        };
    };
    readonly " $refType": "InviteUsersContainer_settings";
};
export type InviteUsersContainer_settings$data = InviteUsersContainer_settings;
export type InviteUsersContainer_settings$key = {
    readonly " $data"?: InviteUsersContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"InviteUsersContainer_settings">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "InviteUsersContainer_settings",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "EmailConfiguration",
                "kind": "LinkedField",
                "name": "email",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Auth",
                "kind": "LinkedField",
                "name": "auth",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuthIntegrations",
                        "kind": "LinkedField",
                        "name": "integrations",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "LocalAuthIntegration",
                                "kind": "LinkedField",
                                "name": "local",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "allowRegistration",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AuthenticationTargetFilter",
                                        "kind": "LinkedField",
                                        "name": "targetFilter",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "admin",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Settings",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '3c8c802456b96355bd0c4d317a21439e';
export default node;
