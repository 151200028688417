/* tslint:disable */
/* eslint-disable */
/* @relayHash 0599f63b59e8b4e0a30ed823a18751d9 */

import { ConcreteRequest } from "relay-runtime";
export type DSAReportHistoryType = "DECISION_MADE" | "NOTE" | "SHARE" | "STATUS_CHANGED" | "%future added value";
export type DSAReportStatus = "AWAITING_REVIEW" | "COMPLETED" | "UNDER_REVIEW" | "VOID" | "%future added value";
export type ChangeDSAReportStatusInput = {
    userID: string;
    status: DSAReportStatus;
    reportID: string;
    clientMutationId: string;
};
export type ChangeReportStatusMutationVariables = {
    input: ChangeDSAReportStatusInput;
};
export type ChangeReportStatusMutationResponse = {
    readonly changeDSAReportStatus: {
        readonly dsaReport: {
            readonly id: string;
            readonly status: DSAReportStatus | null;
            readonly history: ReadonlyArray<{
                readonly id: string;
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
                readonly createdAt: string;
                readonly body: string | null;
                readonly type: DSAReportHistoryType | null;
                readonly status: DSAReportStatus | null;
            } | null> | null;
        } | null;
        readonly clientMutationId: string;
    };
};
export type ChangeReportStatusMutation = {
    readonly response: ChangeReportStatusMutationResponse;
    readonly variables: ChangeReportStatusMutationVariables;
};



/*
mutation ChangeReportStatusMutation(
  $input: ChangeDSAReportStatusInput!
) {
  changeDSAReportStatus(input: $input) {
    dsaReport {
      id
      status
      history {
        id
        createdBy {
          username
          id
        }
        createdAt
        body
        type
        status
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ChangeReportStatusMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ChangeDSAReportStatusPayload",
                    "kind": "LinkedField",
                    "name": "changeDSAReportStatus",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReport",
                            "kind": "LinkedField",
                            "name": "dsaReport",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAReportHistoryItem",
                                    "kind": "LinkedField",
                                    "name": "history",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "createdBy",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v8 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ChangeReportStatusMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ChangeDSAReportStatusPayload",
                    "kind": "LinkedField",
                    "name": "changeDSAReportStatus",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReport",
                            "kind": "LinkedField",
                            "name": "dsaReport",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAReportHistoryItem",
                                    "kind": "LinkedField",
                                    "name": "history",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "createdBy",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v8 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "0599f63b59e8b4e0a30ed823a18751d9",
            "metadata": {},
            "name": "ChangeReportStatusMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '2da5fdfd328ea1ede08bb61dff078bc8';
export default node;
