/* tslint:disable */
/* eslint-disable */
/* @relayHash 0a4bddba12cc65e2d59832b4ba78c126 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SSOSigningSecretRotationQueryVariables = {};
export type SSOSigningSecretRotationQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"SSOSigningSecretRotationContainer_settings">;
    };
};
export type SSOSigningSecretRotationQuery = {
    readonly response: SSOSigningSecretRotationQueryResponse;
    readonly variables: SSOSigningSecretRotationQueryVariables;
};



/*
query SSOSigningSecretRotationQuery {
  settings {
    ...SSOSigningSecretRotationContainer_settings
    id
  }
}

fragment SSOSigningSecretRotationContainer_settings on Settings {
  auth {
    integrations {
      sso {
        enabled
        signingSecrets {
          kid
          secret
          createdAt
          lastUsedAt
          rotatedAt
          inactiveAt
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = ({
    "fragment": {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "SSOSigningSecretRotationQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Settings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SSOSigningSecretRotationContainer_settings"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Query",
        "abstractKey": null
    },
    "kind": "Request",
    "operation": {
        "argumentDefinitions": [],
        "kind": "Operation",
        "name": "SSOSigningSecretRotationQuery",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Settings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Auth",
                        "kind": "LinkedField",
                        "name": "auth",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "AuthIntegrations",
                                "kind": "LinkedField",
                                "name": "integrations",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "SSOAuthIntegration",
                                        "kind": "LinkedField",
                                        "name": "sso",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "enabled",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "SigningSecret",
                                                "kind": "LinkedField",
                                                "name": "signingSecrets",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "kid",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "secret",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "createdAt",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "lastUsedAt",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "rotatedAt",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "inactiveAt",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ]
    },
    "params": {
        "id": "0a4bddba12cc65e2d59832b4ba78c126",
        "metadata": {},
        "name": "SSOSigningSecretRotationQuery",
        "operationKind": "query",
        "text": null
    }
} as any);
(node as any).hash = 'a7c19c30f09b9c4a33ce3edcf8ef82e6';
export default node;
