/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SuccessContainer_settings = {
    readonly organization: {
        readonly name: string;
        readonly url: string;
    };
    readonly " $refType": "SuccessContainer_settings";
};
export type SuccessContainer_settings$data = SuccessContainer_settings;
export type SuccessContainer_settings$key = {
    readonly " $data"?: SuccessContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"SuccessContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SuccessContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '86425ee1931de62d94e8d03dbc09b3b6';
export default node;
