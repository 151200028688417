/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmailDomainConfigContainer_formValues = {
    readonly protectedEmailDomains: ReadonlyArray<string>;
    readonly " $refType": "EmailDomainConfigContainer_formValues";
};
export type EmailDomainConfigContainer_formValues$data = EmailDomainConfigContainer_formValues;
export type EmailDomainConfigContainer_formValues$key = {
    readonly " $data"?: EmailDomainConfigContainer_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailDomainConfigContainer_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailDomainConfigContainer_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "protectedEmailDomains",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'a1fd4d146cd80bfe9b68749783f573b3';
export default node;
