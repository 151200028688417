/* tslint:disable */
/* eslint-disable */
/* @relayHash e894ac3ba5271dc3d2394e3c07227cfc */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DSAREPORT_STATUS_FILTER = "AWAITING_REVIEW" | "COMPLETED" | "UNDER_REVIEW" | "VOID" | "%future added value";
export type REPORT_SORT = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "%future added value";
export type ReportsTableContainerPaginationQueryVariables = {
    count: number;
    cursor?: unknown | null;
    orderBy?: REPORT_SORT | null;
    statusFilter?: Array<DSAREPORT_STATUS_FILTER> | null;
};
export type ReportsTableContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ReportsTableContainer_query">;
};
export type ReportsTableContainerPaginationQuery = {
    readonly response: ReportsTableContainerPaginationQueryResponse;
    readonly variables: ReportsTableContainerPaginationQueryVariables;
};



/*
query ReportsTableContainerPaginationQuery(
  $count: Int!
  $cursor: Cursor
  $orderBy: REPORT_SORT
  $statusFilter: [DSAREPORT_STATUS_FILTER!]
) {
  ...ReportsTableContainer_query_L7aeM
}

fragment ReportRowContainer_dsaReport on DSAReport {
  id
  createdAt
  referenceID
  status
  reporter {
    username
    id
  }
  comment {
    author {
      username
      id
    }
    id
  }
  lawBrokenDescription
  lastUpdated
}

fragment ReportsTableContainer_query_L7aeM on Query {
  dsaReports(first: $count, after: $cursor, orderBy: $orderBy, status: $statusFilter) {
    edges {
      node {
        id
        ...ReportRowContainer_dsaReport
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "count"
        } as any),
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any),
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "orderBy"
        } as any),
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "statusFilter"
        } as any)
    ], v1 = ({
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
    } as any), v2 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        (v1 /*: any*/),
        ({
            "kind": "Variable",
            "name": "status",
            "variableName": "statusFilter"
        } as any)
    ], v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v4 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        } as any),
        (v3 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ReportsTableContainerPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        (v1 /*: any*/),
                        {
                            "kind": "Variable",
                            "name": "statusFilter",
                            "variableName": "statusFilter"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "ReportsTableContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ReportsTableContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "DSAReportsConnection",
                    "kind": "LinkedField",
                    "name": "dsaReports",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReportEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAReport",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "referenceID",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "reporter",
                                            "plural": false,
                                            "selections": (v4 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Comment",
                                            "kind": "LinkedField",
                                            "name": "comment",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "author",
                                                    "plural": false,
                                                    "selections": (v4 /*: any*/),
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lawBrokenDescription",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastUpdated",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "filters": [
                        "orderBy",
                        "status"
                    ],
                    "handle": "connection",
                    "key": "ReportsTable_dsaReports",
                    "kind": "LinkedHandle",
                    "name": "dsaReports"
                }
            ]
        },
        "params": {
            "id": "e894ac3ba5271dc3d2394e3c07227cfc",
            "metadata": {},
            "name": "ReportsTableContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '5f2a5696a879dc4c1b5c841c82380301';
export default node;
