/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MarkersContainer_settings = {
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardDetailsContainer_settings">;
    readonly " $refType": "MarkersContainer_settings";
};
export type MarkersContainer_settings$data = MarkersContainer_settings;
export type MarkersContainer_settings$key = {
    readonly " $data"?: MarkersContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"MarkersContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkersContainer_settings",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateCardDetailsContainer_settings"
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '23385fa53e1d04cdfcca7a7c4333a930';
export default node;
