/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type REJECTION_REASON_CODE = "ABUSIVE" | "AD" | "BANNED_WORD" | "HARASSMENT_BULLYING" | "HATE_SPEECH" | "ILLEGAL_CONTENT" | "IRRELEVANT_CONTENT" | "MISINFORMATION" | "OFFENSIVE" | "OTHER" | "SPAM" | "%future added value";
export type DecisionDetailsContainer_comment = {
    readonly id: string;
    readonly statusHistory: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly createdAt: string;
                readonly rejectionReason: {
                    readonly code: REJECTION_REASON_CODE;
                    readonly legalGrounds: string | null;
                    readonly detailedExplanation: string | null;
                    readonly customReason: string | null;
                } | null;
            };
        }>;
    };
    readonly " $refType": "DecisionDetailsContainer_comment";
};
export type DecisionDetailsContainer_comment$data = DecisionDetailsContainer_comment;
export type DecisionDetailsContainer_comment$key = {
    readonly " $data"?: DecisionDetailsContainer_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"DecisionDetailsContainer_comment">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DecisionDetailsContainer_comment",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                }
            ],
            "concreteType": "CommentModerationActionConnection",
            "kind": "LinkedField",
            "name": "statusHistory",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentModerationActionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentModerationAction",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RejectionReason",
                                    "kind": "LinkedField",
                                    "name": "rejectionReason",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "code",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "legalGrounds",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "detailedExplanation",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "customReason",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "statusHistory(first:1)"
        }
    ],
    "type": "Comment",
    "abstractKey": null
} as any);
(node as any).hash = 'dcf6acdaad2472c786d1ab986b3e1abf';
export default node;
