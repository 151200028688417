/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BanModalLocal = {
    readonly dsaFeaturesEnabled: boolean | null;
    readonly " $refType": "BanModalLocal";
};
export type BanModalLocal$data = BanModalLocal;
export type BanModalLocal$key = {
    readonly " $data"?: BanModalLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"BanModalLocal">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BanModalLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dsaFeaturesEnabled",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = '5bfa6f1b7741e72c740c11e2c5ce1883';
export default node;
