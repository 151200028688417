/* tslint:disable */
/* eslint-disable */
/* @relayHash d00f69e44611868e754af43312cd0142 */

import { ConcreteRequest } from "relay-runtime";
export type NEW_USER_MODERATION = "BAN" | "PREMOD" | "%future added value";
export type CreateEmailDomainInput = {
    clientMutationId: string;
    domain: string;
    newUserModeration: NEW_USER_MODERATION;
};
export type BanDomainMutationVariables = {
    input: CreateEmailDomainInput;
};
export type BanDomainMutationResponse = {
    readonly createEmailDomain: {
        readonly clientMutationId: string;
    };
};
export type BanDomainMutation = {
    readonly response: BanDomainMutationResponse;
    readonly variables: BanDomainMutationVariables;
};



/*
mutation BanDomainMutation(
  $input: CreateEmailDomainInput!
) {
  createEmailDomain(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CreateEmailDomainPayload",
            "kind": "LinkedField",
            "name": "createEmailDomain",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "BanDomainMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "BanDomainMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "d00f69e44611868e754af43312cd0142",
            "metadata": {},
            "name": "BanDomainMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'c497556b679b85375653b2acec428cc6';
export default node;
