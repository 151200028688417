/* tslint:disable */
/* eslint-disable */
/* @relayHash 7fdaf857fa14b14d0240198a562d39be */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConfigureExternalModerationPhaseRouteQueryVariables = {
    phaseID: string;
};
export type ConfigureExternalModerationPhaseRouteQueryResponse = {
    readonly externalModerationPhase: {
        readonly " $fragmentRefs": FragmentRefs<"ConfigureExternalModerationPhaseContainer_phase">;
    } | null;
};
export type ConfigureExternalModerationPhaseRouteQuery = {
    readonly response: ConfigureExternalModerationPhaseRouteQueryResponse;
    readonly variables: ConfigureExternalModerationPhaseRouteQueryVariables;
};



/*
query ConfigureExternalModerationPhaseRouteQuery(
  $phaseID: ID!
) {
  externalModerationPhase(id: $phaseID) {
    ...ConfigureExternalModerationPhaseContainer_phase
    id
  }
}

fragment ConfigureExternalModerationPhaseContainer_phase on ExternalModerationPhase {
  ...ExternalModerationPhaseDetails_phase
  ...ExternalModerationPhaseDangerZone_phase
  ...ExternalModerationPhaseStatus_phase
}

fragment ConfigureExternalModerationPhaseForm_phase on ExternalModerationPhase {
  id
  name
  url
  timeout
  format
}

fragment ExternalModerationPhaseDangerZone_phase on ExternalModerationPhase {
  id
  enabled
}

fragment ExternalModerationPhaseDetails_phase on ExternalModerationPhase {
  ...ConfigureExternalModerationPhaseForm_phase
}

fragment ExternalModerationPhaseStatus_phase on ExternalModerationPhase {
  id
  enabled
  signingSecret {
    secret
    createdAt
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "phaseID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "phaseID"
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ConfigureExternalModerationPhaseRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ExternalModerationPhase",
                    "kind": "LinkedField",
                    "name": "externalModerationPhase",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConfigureExternalModerationPhaseContainer_phase"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ConfigureExternalModerationPhaseRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ExternalModerationPhase",
                    "kind": "LinkedField",
                    "name": "externalModerationPhase",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timeout",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "format",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SigningSecret",
                            "kind": "LinkedField",
                            "name": "signingSecret",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "secret",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "7fdaf857fa14b14d0240198a562d39be",
            "metadata": {},
            "name": "ConfigureExternalModerationPhaseRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '8ef86a9f5b2149a383f6a49f9acb2a48';
export default node;
