/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerateNavigationContainer_moderationQueues = {
    readonly unmoderated: {
        readonly count: number | null;
    };
    readonly reported: {
        readonly count: number | null;
    };
    readonly pending: {
        readonly count: number | null;
    };
    readonly " $refType": "ModerateNavigationContainer_moderationQueues";
};
export type ModerateNavigationContainer_moderationQueues$data = ModerateNavigationContainer_moderationQueues;
export type ModerateNavigationContainer_moderationQueues$key = {
    readonly " $data"?: ModerateNavigationContainer_moderationQueues$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateNavigationContainer_moderationQueues">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
        } as any)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ModerateNavigationContainer_moderationQueues",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ModerationQueue",
                "kind": "LinkedField",
                "name": "unmoderated",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ModerationQueue",
                "kind": "LinkedField",
                "name": "reported",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ModerationQueue",
                "kind": "LinkedField",
                "name": "pending",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            }
        ],
        "type": "ModerationQueues",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'aeb84c03667ee2c423325a71860659e0';
export default node;
