/* tslint:disable */
/* eslint-disable */
/* @relayHash ccb1679aebaee16f07a161abc4ba1ded */

import { ConcreteRequest } from "relay-runtime";
export type DSAReportHistoryType = "DECISION_MADE" | "NOTE" | "SHARE" | "STATUS_CHANGED" | "%future added value";
export type DSAReportStatus = "AWAITING_REVIEW" | "COMPLETED" | "UNDER_REVIEW" | "VOID" | "%future added value";
export type DeleteDSAReportNoteInput = {
    id: string;
    reportID: string;
    clientMutationId: string;
};
export type DeleteReportNoteMutationVariables = {
    input: DeleteDSAReportNoteInput;
};
export type DeleteReportNoteMutationResponse = {
    readonly deleteDSAReportNote: {
        readonly dsaReport: {
            readonly id: string;
            readonly history: ReadonlyArray<{
                readonly id: string;
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
                readonly createdAt: string;
                readonly body: string | null;
                readonly type: DSAReportHistoryType | null;
                readonly status: DSAReportStatus | null;
            } | null> | null;
        } | null;
        readonly clientMutationId: string;
    };
};
export type DeleteReportNoteMutation = {
    readonly response: DeleteReportNoteMutationResponse;
    readonly variables: DeleteReportNoteMutationVariables;
};



/*
mutation DeleteReportNoteMutation(
  $input: DeleteDSAReportNoteInput!
) {
  deleteDSAReportNote(input: $input) {
    dsaReport {
      id
      history {
        id
        createdBy {
          username
          id
        }
        createdAt
        body
        type
        status
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteReportNoteMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteDSAReportNotePayload",
                    "kind": "LinkedField",
                    "name": "deleteDSAReportNote",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReport",
                            "kind": "LinkedField",
                            "name": "dsaReport",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAReportHistoryItem",
                                    "kind": "LinkedField",
                                    "name": "history",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "createdBy",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v8 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteReportNoteMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteDSAReportNotePayload",
                    "kind": "LinkedField",
                    "name": "deleteDSAReportNote",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReport",
                            "kind": "LinkedField",
                            "name": "dsaReport",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAReportHistoryItem",
                                    "kind": "LinkedField",
                                    "name": "history",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "createdBy",
                                            "plural": false,
                                            "selections": [
                                                (v3 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v8 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "ccb1679aebaee16f07a161abc4ba1ded",
            "metadata": {},
            "name": "DeleteReportNoteMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'c008f2518b61370692a8911131e55fa8';
export default node;
