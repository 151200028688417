/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type View = "ADD_EMAIL_ADDRESS" | "CREATE_PASSWORD" | "CREATE_USERNAME" | "LINK_ACCOUNT" | "SIGN_IN" | "%future added value";
export type AccountCompletionContainerLocal = {
    readonly accessToken: string | null;
    readonly authView: View | null;
    readonly authDuplicateEmail: string | null;
    readonly redirectPath: string | null;
    readonly " $refType": "AccountCompletionContainerLocal";
};
export type AccountCompletionContainerLocal$data = AccountCompletionContainerLocal;
export type AccountCompletionContainerLocal$key = {
    readonly " $data"?: AccountCompletionContainerLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountCompletionContainerLocal">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountCompletionContainerLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessToken",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authView",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authDuplicateEmail",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "redirectPath",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = 'ee6c9bbb50b42e27e27b774b8186c8a6';
export default node;
