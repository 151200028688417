/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmailConfigContainer_viewer = {
    readonly email: string | null;
    readonly " $refType": "EmailConfigContainer_viewer";
};
export type EmailConfigContainer_viewer$data = EmailConfigContainer_viewer;
export type EmailConfigContainer_viewer$key = {
    readonly " $data"?: EmailConfigContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailConfigContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailConfigContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = 'e166a789127f6b51467a733d40ddcaad';
export default node;
