/* tslint:disable */
/* eslint-disable */
/* @relayHash 21351b9ee10cf0ef84eff7c1db156131 */

import { ConcreteRequest } from "relay-runtime";
export type ScrapeStoryInput = {
    id: string;
    clientMutationId: string;
};
export type RescrapeStoryMutationVariables = {
    input: ScrapeStoryInput;
};
export type RescrapeStoryMutationResponse = {
    readonly scrapeStory: {
        readonly story: {
            readonly id: string;
            readonly metadata: {
                readonly title: string | null;
            } | null;
        } | null;
        readonly clientMutationId: string;
    };
};
export type RescrapeStoryMutation = {
    readonly response: RescrapeStoryMutationResponse;
    readonly variables: RescrapeStoryMutationVariables;
};



/*
mutation RescrapeStoryMutation(
  $input: ScrapeStoryInput!
) {
  scrapeStory(input: $input) {
    story {
      id
      metadata {
        title
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ScrapeStoryPayload",
            "kind": "LinkedField",
            "name": "scrapeStory",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "story",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "StoryMetadata",
                            "kind": "LinkedField",
                            "name": "metadata",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RescrapeStoryMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RescrapeStoryMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": "21351b9ee10cf0ef84eff7c1db156131",
            "metadata": {},
            "name": "RescrapeStoryMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '112d7b52df3c157c4f59cef76c0a3715';
export default node;
