"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDate = exports.createDateFormatter = void 0;
function createDateFormatter(locales, options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
}) {
    return new Intl.DateTimeFormat(locales, options);
}
exports.createDateFormatter = createDateFormatter;
function formatDate(date, locales) {
    const formatter = createDateFormatter(locales);
    const formattedDate = formatter.format(date);
    return formattedDate;
}
exports.formatDate = formatDate;
