/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ForReviewQueueConfig_formValues = {
    readonly forReviewQueue: boolean;
    readonly " $refType": "ForReviewQueueConfig_formValues";
};
export type ForReviewQueueConfig_formValues$data = ForReviewQueueConfig_formValues;
export type ForReviewQueueConfig_formValues$key = {
    readonly " $data"?: ForReviewQueueConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"ForReviewQueueConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ForReviewQueueConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "forReviewQueue",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'fc38c888fa4121dfb37b61676cff8db7';
export default node;
