/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteSelectorSite_site = {
    readonly name: string;
    readonly id: string;
    readonly " $refType": "SiteSelectorSite_site";
};
export type SiteSelectorSite_site$data = SiteSelectorSite_site;
export type SiteSelectorSite_site$key = {
    readonly " $data"?: SiteSelectorSite_site$data;
    readonly " $fragmentRefs": FragmentRefs<"SiteSelectorSite_site">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteSelectorSite_site",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Site",
    "abstractKey": null
} as any);
(node as any).hash = '86df7e840ca0b720820dfde076b08cd9';
export default node;
