/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerateNavigationContainer_story = {
    readonly id: string;
    readonly isArchiving: boolean;
    readonly isArchived: boolean;
    readonly " $refType": "ModerateNavigationContainer_story";
};
export type ModerateNavigationContainer_story$data = ModerateNavigationContainer_story;
export type ModerateNavigationContainer_story$key = {
    readonly " $data"?: ModerateNavigationContainer_story$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateNavigationContainer_story">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateNavigationContainer_story",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchiving",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchived",
            "storageKey": null
        }
    ],
    "type": "Story",
    "abstractKey": null
} as any);
(node as any).hash = '29316032ae1604e94a9764f6d7851624';
export default node;
