/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GuidelinesConfig_formValues = {
    readonly communityGuidelines: {
        readonly enabled: boolean;
        readonly content: string | null;
    };
    readonly " $refType": "GuidelinesConfig_formValues";
};
export type GuidelinesConfig_formValues$data = GuidelinesConfig_formValues;
export type GuidelinesConfig_formValues$key = {
    readonly " $data"?: GuidelinesConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"GuidelinesConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GuidelinesConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "CommunityGuidelines",
            "kind": "LinkedField",
            "name": "communityGuidelines",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'd954220afa0c8b7fc5fb2d3a7d2bb8e9';
export default node;
