/* tslint:disable */
/* eslint-disable */
/* @relayHash 3a2d806b43af7ed7e15794e31e7b3bdc */

import { ConcreteRequest } from "relay-runtime";
export type MODERATION_MODE = "POST" | "PRE" | "SPECIFIC_SITES_PRE" | "%future added value";
export type ModerationQueueQueryVariables = {};
export type ModerationQueueQueryResponse = {
    readonly settings: {
        readonly moderation: MODERATION_MODE | null;
    };
};
export type ModerationQueueQuery = {
    readonly response: ModerationQueueQueryResponse;
    readonly variables: ModerationQueueQueryVariables;
};



/*
query ModerationQueueQuery {
  settings {
    moderation
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "moderation",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ModerationQueueQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ModerationQueueQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "3a2d806b43af7ed7e15794e31e7b3bdc",
            "metadata": {},
            "name": "ModerationQueueQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'dd3780b3a55081d6d24750e5732f0048';
export default node;
