/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SiteRowContainer_site = {
    readonly id: string;
    readonly name: string;
    readonly createdAt: string;
    readonly " $refType": "SiteRowContainer_site";
};
export type SiteRowContainer_site$data = SiteRowContainer_site;
export type SiteRowContainer_site$key = {
    readonly " $data"?: SiteRowContainer_site$data;
    readonly " $fragmentRefs": FragmentRefs<"SiteRowContainer_site">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteRowContainer_site",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
        }
    ],
    "type": "Site",
    "abstractKey": null
} as any);
(node as any).hash = 'f05ad2ee3448a0c046908ac834c1abf6';
export default node;
