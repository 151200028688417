/* tslint:disable */
/* eslint-disable */
/* @relayHash 3ef76cd8878fdcc1dd724ecf1cf259c5 */

import { ConcreteRequest } from "relay-runtime";
export type CreateModeratorNoteInput = {
    clientMutationId: string;
    body: string;
    userID: string;
};
export type CreateModeratorNoteMutationVariables = {
    input: CreateModeratorNoteInput;
};
export type CreateModeratorNoteMutationResponse = {
    readonly createModeratorNote: {
        readonly user: {
            readonly id: string;
            readonly moderatorNotes: ReadonlyArray<{
                readonly id: string;
                readonly body: string;
                readonly createdBy: {
                    readonly username: string | null;
                    readonly id: string;
                };
                readonly createdAt: string;
            }>;
        };
        readonly clientMutationId: string;
    };
};
export type CreateModeratorNoteMutation = {
    readonly response: CreateModeratorNoteMutationResponse;
    readonly variables: CreateModeratorNoteMutationVariables;
};



/*
mutation CreateModeratorNoteMutation(
  $input: CreateModeratorNoteInput!
) {
  createModeratorNote(input: $input) {
    user {
      id
      moderatorNotes {
        id
        body
        createdBy {
          username
          id
        }
        createdAt
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CreateModeratorNotePayload",
            "kind": "LinkedField",
            "name": "createModeratorNote",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModeratorNote",
                            "kind": "LinkedField",
                            "name": "moderatorNotes",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "body",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "createdBy",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "username",
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateModeratorNoteMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateModeratorNoteMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": "3ef76cd8878fdcc1dd724ecf1cf259c5",
            "metadata": {},
            "name": "CreateModeratorNoteMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '38c629b5330b6edf4e62e2ecdf737153';
export default node;
