/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QueueRoute_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"ModerateCardContainer_viewer">;
    readonly " $refType": "QueueRoute_viewer";
};
export type QueueRoute_viewer$data = QueueRoute_viewer;
export type QueueRoute_viewer$key = {
    readonly " $data"?: QueueRoute_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"QueueRoute_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueueRoute_viewer",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ModerateCardContainer_viewer"
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '58f34c32911483727a7e3bad3b35965c';
export default node;
