/* tslint:disable */
/* eslint-disable */
/* @relayHash 22cc2dbd4a07ecf33b6ba053b7498d72 */

import { ConcreteRequest } from "relay-runtime";
export type StoriesRouteQueryVariables = {};
export type StoriesRouteQueryResponse = {
    readonly viewer: {
        readonly moderationScopes: {
            readonly scoped: boolean;
            readonly sites: ReadonlyArray<{
                readonly id: string;
            }> | null;
        } | null;
    } | null;
};
export type StoriesRouteQuery = {
    readonly response: StoriesRouteQueryResponse;
    readonly variables: StoriesRouteQueryVariables;
};



/*
query StoriesRouteQuery {
  viewer {
    moderationScopes {
      scoped
      sites {
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "concreteType": "UserModerationScopes",
        "kind": "LinkedField",
        "name": "moderationScopes",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scoped",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "sites",
                "plural": true,
                "selections": [
                    (v0 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "StoriesRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "StoriesRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "22cc2dbd4a07ecf33b6ba053b7498d72",
            "metadata": {},
            "name": "StoriesRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '0a6326cef8583c661aea16fd8fdf0173';
export default node;
