/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WebhookEndpointsConfigContainer_settings = {
    readonly webhooks: {
        readonly endpoints: ReadonlyArray<{
            readonly " $fragmentRefs": FragmentRefs<"WebhookEndpointRow_webhookEndpoint">;
        }>;
    };
    readonly " $refType": "WebhookEndpointsConfigContainer_settings";
};
export type WebhookEndpointsConfigContainer_settings$data = WebhookEndpointsConfigContainer_settings;
export type WebhookEndpointsConfigContainer_settings$key = {
    readonly " $data"?: WebhookEndpointsConfigContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"WebhookEndpointsConfigContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebhookEndpointsConfigContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "WebhookConfiguration",
            "kind": "LinkedField",
            "name": "webhooks",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebhookEndpoint",
                    "kind": "LinkedField",
                    "name": "endpoints",
                    "plural": true,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "WebhookEndpointRow_webhookEndpoint"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '784e5f8a3ff71e8bfd7368629f1bf735';
export default node;
