/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerateStoryButton_story = {
    readonly canModerate: boolean;
    readonly isArchiving: boolean;
    readonly isArchived: boolean;
    readonly id: string;
    readonly " $refType": "ModerateStoryButton_story";
};
export type ModerateStoryButton_story$data = ModerateStoryButton_story;
export type ModerateStoryButton_story$key = {
    readonly " $data"?: ModerateStoryButton_story$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerateStoryButton_story">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerateStoryButton_story",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canModerate",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchiving",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchived",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "Story",
    "abstractKey": null
} as any);
(node as any).hash = '771381861825b5bcc649d0c88a429168';
export default node;
