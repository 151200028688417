/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FeaturedByConfig_formValues = {
    readonly featuredBy: boolean | null;
    readonly " $refType": "FeaturedByConfig_formValues";
};
export type FeaturedByConfig_formValues$data = FeaturedByConfig_formValues;
export type FeaturedByConfig_formValues$key = {
    readonly " $data"?: FeaturedByConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"FeaturedByConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturedByConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featuredBy",
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '02517a3a16075b40278ed43e30709abe';
export default node;
