/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConfigureWebhookEndpointContainer_webhookEndpoint = {
    readonly " $fragmentRefs": FragmentRefs<"EndpointDangerZone_webhookEndpoint" | "EndpointDetails_webhookEndpoint" | "EndpointStatus_webhookEndpoint">;
    readonly " $refType": "ConfigureWebhookEndpointContainer_webhookEndpoint";
};
export type ConfigureWebhookEndpointContainer_webhookEndpoint$data = ConfigureWebhookEndpointContainer_webhookEndpoint;
export type ConfigureWebhookEndpointContainer_webhookEndpoint$key = {
    readonly " $data"?: ConfigureWebhookEndpointContainer_webhookEndpoint$data;
    readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointContainer_webhookEndpoint">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureWebhookEndpointContainer_webhookEndpoint",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EndpointDangerZone_webhookEndpoint"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EndpointDetails_webhookEndpoint"
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EndpointStatus_webhookEndpoint"
        }
    ],
    "type": "WebhookEndpoint",
    "abstractKey": null
} as any);
(node as any).hash = 'b820cb7c2f94998333ba15f34b6d33c0';
export default node;
