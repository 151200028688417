/* tslint:disable */
/* eslint-disable */
/* @relayHash c808f040b2483106ae9ebae7cca5b484 */

import { ConcreteRequest } from "relay-runtime";
export type CreateAnnouncementInput = {
    content: string;
    duration: number;
    clientMutationId: string;
};
export type CreateAnnouncementMutationVariables = {
    input: CreateAnnouncementInput;
};
export type CreateAnnouncementMutationResponse = {
    readonly createAnnouncement: {
        readonly settings: {
            readonly announcement: {
                readonly id: string;
                readonly content: string;
                readonly createdAt: string;
                readonly duration: number;
            } | null;
        };
        readonly clientMutationId: string;
    };
};
export type CreateAnnouncementMutation = {
    readonly response: CreateAnnouncementMutationResponse;
    readonly variables: CreateAnnouncementMutationVariables;
};



/*
mutation CreateAnnouncementMutation(
  $input: CreateAnnouncementInput!
) {
  createAnnouncement(input: $input) {
    settings {
      announcement {
        id
        content
        createdAt
        duration
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "concreteType": "Announcement",
        "kind": "LinkedField",
        "name": "announcement",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateAnnouncementMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateAnnouncementPayload",
                    "kind": "LinkedField",
                    "name": "createAnnouncement",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateAnnouncementMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateAnnouncementPayload",
                    "kind": "LinkedField",
                    "name": "createAnnouncement",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "c808f040b2483106ae9ebae7cca5b484",
            "metadata": {},
            "name": "CreateAnnouncementMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '71a8bd610bfe9cdff4ffdaa8b4daeba0';
export default node;
