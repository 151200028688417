/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type InviteUsersContainer_viewer = {
    readonly role: USER_ROLE;
    readonly " $refType": "InviteUsersContainer_viewer";
};
export type InviteUsersContainer_viewer$data = InviteUsersContainer_viewer;
export type InviteUsersContainer_viewer$key = {
    readonly " $data"?: InviteUsersContainer_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"InviteUsersContainer_viewer">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteUsersContainer_viewer",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '3163a41639bc310cbc6c5887fee55cf8';
export default node;
