/* tslint:disable */
/* eslint-disable */
/* @relayHash 8a0e51a1a141c5195f39cd2bc777f091 */

import { ConcreteRequest } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type UpdateUserModerationScopesInput = {
    userID: string;
    moderationScopes: ModerationScopesInput;
    clientMutationId: string;
};
export type ModerationScopesInput = {
    scoped: boolean;
    siteIDs: Array<string>;
};
export type UpdateUserModerationScopesMutationVariables = {
    input: UpdateUserModerationScopesInput;
};
export type UpdateUserModerationScopesMutationResponse = {
    readonly updateUserModerationScopes: {
        readonly user: {
            readonly id: string;
            readonly role: USER_ROLE;
            readonly moderationScopes: {
                readonly scoped: boolean;
                readonly sites: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                }> | null;
            } | null;
        };
        readonly clientMutationId: string;
    };
};
export type UpdateUserModerationScopesMutation = {
    readonly response: UpdateUserModerationScopesMutationResponse;
    readonly variables: UpdateUserModerationScopesMutationVariables;
};



/*
mutation UpdateUserModerationScopesMutation(
  $input: UpdateUserModerationScopesInput!
) {
  updateUserModerationScopes(input: $input) {
    user {
      id
      role
      moderationScopes {
        scoped
        sites {
          id
          name
        }
      }
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateUserModerationScopesPayload",
            "kind": "LinkedField",
            "name": "updateUserModerationScopes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserModerationScopes",
                            "kind": "LinkedField",
                            "name": "moderationScopes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scoped",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "sites",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateUserModerationScopesMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateUserModerationScopesMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": "8a0e51a1a141c5195f39cd2bc777f091",
            "metadata": {},
            "name": "UpdateUserModerationScopesMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '95ee7b4757c549100958c09d30cfdaf3';
export default node;
