/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserStatusDetailsContainer_user = {
    readonly status: {
        readonly warning: {
            readonly active: boolean;
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly createdBy: {
                    readonly username: string | null;
                };
                readonly createdAt: string;
            }>;
        };
        readonly ban: {
            readonly active: boolean;
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly createdAt: string;
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
            }>;
        };
        readonly suspension: {
            readonly until: string | null;
            readonly active: boolean;
            readonly history: ReadonlyArray<{
                readonly active: boolean;
                readonly from: {
                    readonly start: string;
                    readonly finish: string;
                };
                readonly createdBy: {
                    readonly username: string | null;
                } | null;
            }>;
        };
    };
    readonly " $refType": "UserStatusDetailsContainer_user";
};
export type UserStatusDetailsContainer_user$data = UserStatusDetailsContainer_user;
export type UserStatusDetailsContainer_user$key = {
    readonly " $data"?: UserStatusDetailsContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserStatusDetailsContainer_user">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "createdBy",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any);
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "UserStatusDetailsContainer_user",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "UserStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "WarningStatus",
                        "kind": "LinkedField",
                        "name": "warning",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "WarningStatusHistory",
                                "kind": "LinkedField",
                                "name": "history",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    (v2 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "BanStatus",
                        "kind": "LinkedField",
                        "name": "ban",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "BanStatusHistory",
                                "kind": "LinkedField",
                                "name": "history",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v2 /*: any*/),
                                    (v1 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SuspensionStatus",
                        "kind": "LinkedField",
                        "name": "suspension",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "until",
                                "storageKey": null
                            },
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "SuspensionStatusHistory",
                                "kind": "LinkedField",
                                "name": "history",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TimeRange",
                                        "kind": "LinkedField",
                                        "name": "from",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "start",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "finish",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    (v1 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'ce9c27e93a4fbf527d286821c74c7fe7';
export default node;
