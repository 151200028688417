/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WEBHOOK_EVENT_NAME = "COMMENT_CREATED" | "COMMENT_ENTERED_MODERATION_QUEUE" | "COMMENT_LEFT_MODERATION_QUEUE" | "COMMENT_REPLY_CREATED" | "STORY_CREATED" | "%future added value";
export type ConfigureWebhookEndpointForm_webhookEndpoint = {
    readonly id: string;
    readonly url: string;
    readonly events: ReadonlyArray<WEBHOOK_EVENT_NAME>;
    readonly all: boolean;
    readonly " $refType": "ConfigureWebhookEndpointForm_webhookEndpoint";
};
export type ConfigureWebhookEndpointForm_webhookEndpoint$data = ConfigureWebhookEndpointForm_webhookEndpoint;
export type ConfigureWebhookEndpointForm_webhookEndpoint$key = {
    readonly " $data"?: ConfigureWebhookEndpointForm_webhookEndpoint$data;
    readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointForm_webhookEndpoint">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigureWebhookEndpointForm_webhookEndpoint",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "events",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "all",
            "storageKey": null
        }
    ],
    "type": "WebhookEndpoint",
    "abstractKey": null
} as any);
(node as any).hash = 'f2ef9a0c823792ffef4e1e7968acc946';
export default node;
