/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NEW_USER_MODERATION = "BAN" | "PREMOD" | "%future added value";
export type EmailDomainTableContainer_settings = {
    readonly emailDomainModeration: ReadonlyArray<{
        readonly domain: string;
        readonly id: string;
        readonly newUserModeration: NEW_USER_MODERATION;
    }>;
    readonly " $refType": "EmailDomainTableContainer_settings";
};
export type EmailDomainTableContainer_settings$data = EmailDomainTableContainer_settings;
export type EmailDomainTableContainer_settings$key = {
    readonly " $data"?: EmailDomainTableContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailDomainTableContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailDomainTableContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "EmailDomain",
            "kind": "LinkedField",
            "name": "emailDomainModeration",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domain",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "newUserModeration",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'dc2c939268a0841d5f28983b0c714966';
export default node;
