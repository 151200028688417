/* tslint:disable */
/* eslint-disable */
/* @relayHash e78ca72006d1d01ce8df6381eb7520a3 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConfigureWebhookEndpointRouteQueryVariables = {
    webhookEndpointID: string;
};
export type ConfigureWebhookEndpointRouteQueryResponse = {
    readonly webhookEndpoint: {
        readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointContainer_webhookEndpoint">;
    } | null;
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"ConfigureWebhookEndpointContainer_settings">;
    };
};
export type ConfigureWebhookEndpointRouteQuery = {
    readonly response: ConfigureWebhookEndpointRouteQueryResponse;
    readonly variables: ConfigureWebhookEndpointRouteQueryVariables;
};



/*
query ConfigureWebhookEndpointRouteQuery(
  $webhookEndpointID: ID!
) {
  webhookEndpoint(id: $webhookEndpointID) {
    ...ConfigureWebhookEndpointContainer_webhookEndpoint
    id
  }
  settings {
    ...ConfigureWebhookEndpointContainer_settings
    id
  }
}

fragment ConfigureWebhookEndpointContainer_settings on Settings {
  ...EndpointDetails_settings
}

fragment ConfigureWebhookEndpointContainer_webhookEndpoint on WebhookEndpoint {
  ...EndpointDangerZone_webhookEndpoint
  ...EndpointDetails_webhookEndpoint
  ...EndpointStatus_webhookEndpoint
}

fragment ConfigureWebhookEndpointForm_settings on Settings {
  ...EventsSelectField_settings
}

fragment ConfigureWebhookEndpointForm_webhookEndpoint on WebhookEndpoint {
  id
  url
  events
  all
}

fragment EndpointDangerZone_webhookEndpoint on WebhookEndpoint {
  id
  enabled
}

fragment EndpointDetails_settings on Settings {
  ...ConfigureWebhookEndpointForm_settings
}

fragment EndpointDetails_webhookEndpoint on WebhookEndpoint {
  ...ConfigureWebhookEndpointForm_webhookEndpoint
}

fragment EndpointStatus_webhookEndpoint on WebhookEndpoint {
  id
  enabled
  signingSecret {
    secret
    createdAt
  }
}

fragment EventsSelectField_settings on Settings {
  webhookEvents
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "webhookEndpointID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "webhookEndpointID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ConfigureWebhookEndpointRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "WebhookEndpoint",
                    "kind": "LinkedField",
                    "name": "webhookEndpoint",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConfigureWebhookEndpointContainer_webhookEndpoint"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConfigureWebhookEndpointContainer_settings"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ConfigureWebhookEndpointRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "WebhookEndpoint",
                    "kind": "LinkedField",
                    "name": "webhookEndpoint",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "events",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "all",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SigningSecret",
                            "kind": "LinkedField",
                            "name": "signingSecret",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "secret",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "webhookEvents",
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "e78ca72006d1d01ce8df6381eb7520a3",
            "metadata": {},
            "name": "ConfigureWebhookEndpointRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '27f718afd16cd42536849534676d95a2';
export default node;
