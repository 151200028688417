"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateRoleChange = void 0;
const isSiteModerationScoped_1 = require("./isSiteModerationScoped");
const types_1 = require("./types");
const validateModeratorRoleChange = (viewer, user, role, scoped) => {
    // Org mods may promote users to site moderators within their scope,
    // but only if the user < org mod
    const viewerIsOrgMod = types_1.isOrgModerator(viewer);
    const roleIsSiteMod = role === "MODERATOR" && scoped;
    const userLTOrgMod = isSiteModerationScoped_1.isSiteModerationScoped(user.moderationScopes) || user.role !== "MODERATOR";
    if (viewerIsOrgMod &&
        userLTOrgMod &&
        (roleIsSiteMod || role === "COMMENTER" || role === "MEMBER")) {
        return true;
    }
    if (roleIsSiteMod) {
        return true;
    }
    return false;
};
exports.validateRoleChange = (viewer, user, role, 
/**
 * TODO: this optional argument that is only relevant
 * for one subset of inputs is s a code smell! we need
 * to split the MODERATOR role into ORGANIZATION_MODERATOR + SITE_MODERATOR!!!
 */
scoped = false) => {
    // Viewer is changing their own role
    if (user.id === viewer.id) {
        return false;
    }
    // User is admin
    if (viewer.role === "ADMIN") {
        return true;
    }
    // User is org/site moderator
    if (types_1.isModerator(viewer) && types_1.isLTEModerator(user)) {
        return validateModeratorRoleChange(viewer, user, role, scoped);
    }
    // No one else may update users roles
    return false;
};
