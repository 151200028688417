/* tslint:disable */
/* eslint-disable */
/* @relayHash 305bc3a06211753612df94c6bcfd3dc4 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AuthConfigRouteContainerQueryVariables = {};
export type AuthConfigRouteContainerQueryResponse = {
    readonly settings: {
        readonly auth: {
            readonly " $fragmentRefs": FragmentRefs<"AuthConfigContainer_auth">;
        };
        readonly " $fragmentRefs": FragmentRefs<"AuthConfigContainer_settings">;
    };
};
export type AuthConfigRouteContainerQuery = {
    readonly response: AuthConfigRouteContainerQueryResponse;
    readonly variables: AuthConfigRouteContainerQueryVariables;
};



/*
query AuthConfigRouteContainerQuery {
  settings {
    ...AuthConfigContainer_settings
    auth {
      ...AuthConfigContainer_auth
    }
    id
  }
}

fragment AuthConfigContainer_auth on Auth {
  integrations {
    facebook {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
      clientID
      clientSecret
    }
    google {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
      clientID
      clientSecret
    }
    sso {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
    }
    local {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
    }
    oidc {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
      name
      clientID
      clientSecret
      authorizationURL
      tokenURL
      jwksURI
      issuer
    }
  }
  sessionDuration
  ...FacebookConfigContainer_auth
  ...GoogleConfigContainer_auth
  ...SSOConfigContainer_auth
  ...OIDCConfigContainer_auth
}

fragment AuthConfigContainer_settings on Settings {
  accountFeatures {
    changeUsername
    deleteAccount
    downloadComments
  }
}

fragment FacebookConfigContainer_auth on Auth {
  integrations {
    facebook {
      callbackURL
    }
  }
}

fragment GoogleConfigContainer_auth on Auth {
  integrations {
    google {
      callbackURL
    }
  }
}

fragment OIDCConfigContainer_auth on Auth {
  integrations {
    oidc {
      callbackURL
    }
  }
}

fragment SSOConfigContainer_auth on Auth {
  ...SSOConfig_formValues
}

fragment SSOConfig_formValues on Auth {
  integrations {
    sso {
      enabled
      allowRegistration
      targetFilter {
        admin
        stream
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
    } as any), v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allowRegistration",
        "storageKey": null
    } as any), v2 = ({
        "alias": null,
        "args": null,
        "concreteType": "AuthenticationTargetFilter",
        "kind": "LinkedField",
        "name": "targetFilter",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "admin",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stream",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientID",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientSecret",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "callbackURL",
        "storageKey": null
    } as any), v6 = [
        (v0 /*: any*/),
        (v1 /*: any*/),
        (v2 /*: any*/),
        (v3 /*: any*/),
        (v4 /*: any*/),
        (v5 /*: any*/)
    ], v7 = [
        (v0 /*: any*/),
        (v1 /*: any*/),
        (v2 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AuthConfigRouteContainerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Auth",
                            "kind": "LinkedField",
                            "name": "auth",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "AuthConfigContainer_auth"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AuthConfigContainer_settings"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AuthConfigRouteContainerQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommenterAccountFeatures",
                            "kind": "LinkedField",
                            "name": "accountFeatures",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "changeUsername",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "deleteAccount",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "downloadComments",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Auth",
                            "kind": "LinkedField",
                            "name": "auth",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AuthIntegrations",
                                    "kind": "LinkedField",
                                    "name": "integrations",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "FacebookAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "facebook",
                                            "plural": false,
                                            "selections": (v6 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "GoogleAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "google",
                                            "plural": false,
                                            "selections": (v6 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SSOAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "sso",
                                            "plural": false,
                                            "selections": (v7 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "LocalAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "local",
                                            "plural": false,
                                            "selections": (v7 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "OIDCAuthIntegration",
                                            "kind": "LinkedField",
                                            "name": "oidc",
                                            "plural": false,
                                            "selections": [
                                                (v0 /*: any*/),
                                                (v1 /*: any*/),
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                (v3 /*: any*/),
                                                (v4 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "authorizationURL",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "tokenURL",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "jwksURI",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "issuer",
                                                    "storageKey": null
                                                },
                                                (v5 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "sessionDuration",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "305bc3a06211753612df94c6bcfd3dc4",
            "metadata": {},
            "name": "AuthConfigRouteContainerQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '2b34900934a3e2a1cfab8bada9187b03';
export default node;
