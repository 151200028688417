/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DeleteAccountPopoverContainer_user = {
    readonly id: string;
    readonly username: string | null;
    readonly scheduledDeletionDate: string | null;
    readonly " $refType": "DeleteAccountPopoverContainer_user";
};
export type DeleteAccountPopoverContainer_user$data = DeleteAccountPopoverContainer_user;
export type DeleteAccountPopoverContainer_user$key = {
    readonly " $data"?: DeleteAccountPopoverContainer_user$data;
    readonly " $fragmentRefs": FragmentRefs<"DeleteAccountPopoverContainer_user">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAccountPopoverContainer_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scheduledDeletionDate",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any);
(node as any).hash = '554b78cae20056adda2b4415612ba7e0';
export default node;
