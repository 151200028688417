/* tslint:disable */
/* eslint-disable */
/* @relayHash 1e90c7afa64cbb39a35f025c05b3eba3 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type USER_ROLE = "ADMIN" | "COMMENTER" | "MEMBER" | "MODERATOR" | "STAFF" | "%future added value";
export type USER_STATUS_FILTER = "ACTIVE" | "BANNED" | "PREMOD" | "SITE_BANNED" | "SUSPENDED" | "WARNED" | "%future added value";
export type ExpertSelectionContainerPaginationQueryVariables = {
    storyID: string;
    count: number;
    cursor?: unknown | null;
    roleFilter?: USER_ROLE | null;
    statusFilter?: USER_STATUS_FILTER | null;
    searchFilter?: string | null;
};
export type ExpertSelectionContainerPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ExpertSelectionContainer_query">;
};
export type ExpertSelectionContainerPaginationQuery = {
    readonly response: ExpertSelectionContainerPaginationQueryResponse;
    readonly variables: ExpertSelectionContainerPaginationQueryVariables;
};



/*
query ExpertSelectionContainerPaginationQuery(
  $storyID: ID!
  $count: Int!
  $cursor: Cursor
  $roleFilter: USER_ROLE
  $statusFilter: USER_STATUS_FILTER
  $searchFilter: String
) {
  ...ExpertSelectionContainer_query_47OjS
}

fragment ExpertSelectionContainer_query_47OjS on Query {
  viewer {
    id
    username
  }
  story(id: $storyID) {
    settings {
      experts {
        id
        email
        username
      }
    }
    id
  }
  users(first: $count, after: $cursor, role: $roleFilter, status: $statusFilter, query: $searchFilter) {
    edges {
      node {
        id
        email
        username
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any), v2 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "roleFilter"
    } as any), v3 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "searchFilter"
    } as any), v4 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "statusFilter"
    } as any), v5 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storyID"
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any), v9 = [
        ({
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any),
        ({
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any),
        ({
            "kind": "Variable",
            "name": "query",
            "variableName": "searchFilter"
        } as any),
        ({
            "kind": "Variable",
            "name": "role",
            "variableName": "roleFilter"
        } as any),
        ({
            "kind": "Variable",
            "name": "status",
            "variableName": "statusFilter"
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ExpertSelectionContainerPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        {
                            "kind": "Variable",
                            "name": "roleFilter",
                            "variableName": "roleFilter"
                        },
                        {
                            "kind": "Variable",
                            "name": "searchFilter",
                            "variableName": "searchFilter"
                        },
                        {
                            "kind": "Variable",
                            "name": "statusFilter",
                            "variableName": "statusFilter"
                        },
                        {
                            "kind": "Variable",
                            "name": "storyID",
                            "variableName": "storyID"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "ExpertSelectionContainer_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v5 /*: any*/),
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v4 /*: any*/),
                (v3 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ExpertSelectionContainerPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v6 /*: any*/),
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "storyID"
                        }
                    ],
                    "concreteType": "Story",
                    "kind": "LinkedField",
                    "name": "story",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "StorySettings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "experts",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v8 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v6 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v9 /*: any*/),
                    "concreteType": "UsersConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v8 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v9 /*: any*/),
                    "filters": [
                        "role",
                        "status",
                        "query"
                    ],
                    "handle": "connection",
                    "key": "ExpertSelection_users",
                    "kind": "LinkedHandle",
                    "name": "users"
                }
            ]
        },
        "params": {
            "id": "1e90c7afa64cbb39a35f025c05b3eba3",
            "metadata": {},
            "name": "ExpertSelectionContainerPaginationQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'a87f2e19fbd58355f259ab8fb69b9f62';
export default node;
