/* tslint:disable */
/* eslint-disable */
/* @relayHash ce385525a47a030dc639e9719d35bd18 */

import { ConcreteRequest } from "relay-runtime";
export type MODERATION_QUEUE = "PENDING" | "REPORTED" | "UNMODERATED" | "%future added value";
export type ModerateCountsCommentLeftSubscriptionVariables = {
    storyID?: string | null;
    siteID?: string | null;
};
export type ModerateCountsCommentLeftSubscriptionResponse = {
    readonly commentLeftModerationQueue: {
        readonly queue: MODERATION_QUEUE;
    };
};
export type ModerateCountsCommentLeftSubscription = {
    readonly response: ModerateCountsCommentLeftSubscriptionResponse;
    readonly variables: ModerateCountsCommentLeftSubscriptionVariables;
};



/*
subscription ModerateCountsCommentLeftSubscription(
  $storyID: ID
  $siteID: ID
) {
  commentLeftModerationQueue(storyID: $storyID, siteID: $siteID) {
    queue
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "siteID"
    } as any), v1 = ({
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "storyID"
    } as any), v2 = [
        ({
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "siteID",
                    "variableName": "siteID"
                },
                {
                    "kind": "Variable",
                    "name": "storyID",
                    "variableName": "storyID"
                }
            ],
            "concreteType": "CommentLeftModerationQueuePayload",
            "kind": "LinkedField",
            "name": "commentLeftModerationQueue",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "queue",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ModerateCountsCommentLeftSubscription",
            "selections": (v2 /*: any*/),
            "type": "Subscription",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ModerateCountsCommentLeftSubscription",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": "ce385525a47a030dc639e9719d35bd18",
            "metadata": {},
            "name": "ModerateCountsCommentLeftSubscription",
            "operationKind": "subscription",
            "text": null
        }
    } as any;
})();
(node as any).hash = '590e3571f0ce58e2af421fcd539e0db9';
export default node;
