/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReactionConfig_formValues = {
    readonly reaction: {
        readonly label: string;
        readonly labelActive: string;
        readonly sortLabel: string;
        readonly icon: string;
        readonly iconActive: string | null;
    };
    readonly " $refType": "ReactionConfig_formValues";
};
export type ReactionConfig_formValues$data = ReactionConfig_formValues;
export type ReactionConfig_formValues$key = {
    readonly " $data"?: ReactionConfig_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"ReactionConfig_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReactionConfig_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ReactionConfiguration",
            "kind": "LinkedField",
            "name": "reaction",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "labelActive",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sortLabel",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconActive",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'a7e06536a3c2b94159596f7aea077de7';
export default node;
