/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkAccountContainerLocal = {
    readonly authDuplicateEmail: string | null;
    readonly " $refType": "LinkAccountContainerLocal";
};
export type LinkAccountContainerLocal$data = LinkAccountContainerLocal;
export type LinkAccountContainerLocal$key = {
    readonly " $data"?: LinkAccountContainerLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"LinkAccountContainerLocal">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkAccountContainerLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authDuplicateEmail",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = '98a4827ddd48bb674224a2f37e816ee6';
export default node;
