import React, { FunctionComponent } from "react";

const RatingHalfStarIcon: FunctionComponent = () => {
  // https://www.streamlinehq.com/icons/core-solid/interface-essential/trophy-award/half-star-2
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_126_593)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5222 0.0505067C11.2485 0.104313 10.9917 0.222769 10.7731 0.395997C10.5545 0.569224 10.3805 0.792202 10.2656 1.04634L7.59773 6.68824C7.59309 6.69713 7.5886 6.70611 7.58428 6.71516C7.5821 6.72037 7.57865 6.72495 7.57424 6.72848C7.56983 6.732 7.5646 6.73437 7.55904 6.73534L7.54895 6.73702L1.60089 7.59492L1.58744 7.5966C1.25282 7.65022 0.93989 7.79644 0.684087 8.01873C0.428284 8.24101 0.239821 8.53047 0.140036 8.85434C0.0402505 9.1782 0.0331286 9.52353 0.119477 9.85124C0.205824 10.1789 0.382193 10.4759 0.628614 10.7086L4.91471 15.1175L4.91976 15.1225C4.93413 15.1372 4.94494 15.155 4.95135 15.1746C4.95777 15.1941 4.95962 15.2148 4.95677 15.2352L3.88356 21.4457V21.449C3.82625 21.7761 3.86142 22.1127 3.98511 22.4209C4.10879 22.7291 4.31607 22.9966 4.58361 23.1933C4.85114 23.39 5.16828 23.5081 5.49932 23.5343C5.83035 23.5605 6.16212 23.4937 6.45724 23.3415L11.761 20.4549L17.0043 23.3095C17.3311 23.4802 17.6993 23.556 18.067 23.5281C18.4347 23.5002 18.7872 23.3699 19.0846 23.1518C19.382 22.9338 19.6123 22.6368 19.7495 22.2945C19.8866 21.9522 19.9251 21.5783 19.8606 21.2152V21.2119L18.8159 15.173L22.9877 10.8818C23.2588 10.624 23.4526 10.2956 23.5471 9.9336C23.6416 9.57158 23.6331 9.19037 23.5226 8.83291C23.4121 8.47546 23.2039 8.15599 22.9216 7.9105C22.6392 7.66502 22.2939 7.50328 21.9245 7.44352L21.9077 7.44016L16.1682 6.61255L13.5828 1.14558C13.429 0.808436 13.1819 0.522362 12.8707 0.321158C12.5595 0.119955 12.1972 0.0120298 11.8266 0.0101353C11.7241 0.00987278 11.6221 0.0246073 11.5239 0.053871L11.5222 0.0505067ZM14.2927 7.56632L12.8746 4.5637V18.6685L17.7461 21.3195L16.7368 15.4741L16.7334 15.4623C16.6833 15.1514 16.7069 14.833 16.8025 14.5329C16.898 14.2328 17.0628 13.9594 17.2835 13.7347L21.4064 9.49574L15.7814 8.68326C15.4585 8.63971 15.1521 8.51419 14.8915 8.31867C14.6309 8.12314 14.4248 7.86413 14.2927 7.56632Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_126_593">
          <rect
            width="23.55"
            height="23.55"
            fill="currentColor"
            transform="translate(0.0617676)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RatingHalfStarIcon;
