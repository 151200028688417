/* tslint:disable */
/* eslint-disable */
/* @relayHash 6a4ab9c1d8800cca582d275ded512a49 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerationPhasesConfigRouteQueryVariables = {};
export type ModerationPhasesConfigRouteQueryResponse = {
    readonly settings: {
        readonly " $fragmentRefs": FragmentRefs<"ModerationPhasesConfigContainer_settings">;
    };
};
export type ModerationPhasesConfigRouteQuery = {
    readonly response: ModerationPhasesConfigRouteQueryResponse;
    readonly variables: ModerationPhasesConfigRouteQueryVariables;
};



/*
query ModerationPhasesConfigRouteQuery {
  settings {
    ...ModerationPhasesConfigContainer_settings
    id
  }
}

fragment ExternalModerationPhaseRow_phase on ExternalModerationPhase {
  id
  name
  enabled
}

fragment ModerationPhasesConfigContainer_settings on Settings {
  integrations {
    external {
      phases {
        ...ExternalModerationPhaseRow_phase
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ModerationPhasesConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ModerationPhasesConfigContainer_settings"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ModerationPhasesConfigRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Settings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalIntegrations",
                            "kind": "LinkedField",
                            "name": "integrations",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CustomExternalIntegration",
                                    "kind": "LinkedField",
                                    "name": "external",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ExternalModerationPhase",
                                            "kind": "LinkedField",
                                            "name": "phases",
                                            "plural": true,
                                            "selections": [
                                                (v0 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "enabled",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "6a4ab9c1d8800cca582d275ded512a49",
            "metadata": {},
            "name": "ModerationPhasesConfigRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'd2db4558e629027fa0c6529abeb84931';
export default node;
