/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentStreamLiveUpdatesContainer_settings = {
    readonly live: {
        readonly configurable: boolean;
    };
    readonly " $refType": "CommentStreamLiveUpdatesContainer_settings";
};
export type CommentStreamLiveUpdatesContainer_settings$data = CommentStreamLiveUpdatesContainer_settings;
export type CommentStreamLiveUpdatesContainer_settings$key = {
    readonly " $data"?: CommentStreamLiveUpdatesContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentStreamLiveUpdatesContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentStreamLiveUpdatesContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "LiveConfiguration",
            "kind": "LinkedField",
            "name": "live",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "configurable",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = '949160225b15bb16f69e635cc0092da8';
export default node;
