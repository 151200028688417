/* tslint:disable */
/* eslint-disable */
/* @relayHash 2b7c927068e2e9a3d85146cb1240dbab */

import { ConcreteRequest } from "relay-runtime";
export type SiteRoleModalSelectedSiteQueryVariables = {
    siteID: string;
};
export type SiteRoleModalSelectedSiteQueryResponse = {
    readonly site: {
        readonly name: string;
    } | null;
};
export type SiteRoleModalSelectedSiteQuery = {
    readonly response: SiteRoleModalSelectedSiteQueryResponse;
    readonly variables: SiteRoleModalSelectedSiteQueryVariables;
};



/*
query SiteRoleModalSelectedSiteQuery(
  $siteID: ID!
) {
  site(id: $siteID) {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "siteID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "siteID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SiteRoleModalSelectedSiteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SiteRoleModalSelectedSiteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "2b7c927068e2e9a3d85146cb1240dbab",
            "metadata": {},
            "name": "SiteRoleModalSelectedSiteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '776ca7bf3554c2f538ff8850d5051745';
export default node;
