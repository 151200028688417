/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LocalAuthConfigContainerLocal = {
    readonly forceAdminLocalAuth: boolean;
    readonly " $refType": "LocalAuthConfigContainerLocal";
};
export type LocalAuthConfigContainerLocal$data = LocalAuthConfigContainerLocal;
export type LocalAuthConfigContainerLocal$key = {
    readonly " $data"?: LocalAuthConfigContainerLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"LocalAuthConfigContainerLocal">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocalAuthConfigContainerLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forceAdminLocalAuth",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = '47c4b815ee359eac53f8bc28cd40102b';
export default node;
