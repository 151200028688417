/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LocalAuthConfigContainer_formValues = {
    readonly integrations: {
        readonly local: {
            readonly enabled: boolean;
            readonly allowRegistration: boolean;
            readonly targetFilter: {
                readonly admin: boolean;
                readonly stream: boolean;
            };
        };
    };
    readonly " $refType": "LocalAuthConfigContainer_formValues";
};
export type LocalAuthConfigContainer_formValues$data = LocalAuthConfigContainer_formValues;
export type LocalAuthConfigContainer_formValues$key = {
    readonly " $data"?: LocalAuthConfigContainer_formValues$data;
    readonly " $fragmentRefs": FragmentRefs<"LocalAuthConfigContainer_formValues">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocalAuthConfigContainer_formValues",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocalAuthIntegration",
                    "kind": "LinkedField",
                    "name": "local",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowRegistration",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthenticationTargetFilter",
                            "kind": "LinkedField",
                            "name": "targetFilter",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "admin",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "stream",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any);
(node as any).hash = 'c434386effc65e757f772f4722589a0f';
export default node;
