/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StoryRowContainer_story = {
    readonly id: string;
    readonly metadata: {
        readonly title: string | null;
        readonly author: string | null;
        readonly publishedAt: string | null;
    } | null;
    readonly commentCounts: {
        readonly totalPublished: number;
    };
    readonly moderationQueues: {
        readonly reported: {
            readonly count: number | null;
        };
        readonly pending: {
            readonly count: number | null;
        };
    };
    readonly viewerCount: number | null;
    readonly site: {
        readonly name: string;
        readonly id: string;
    };
    readonly canModerate: boolean;
    readonly isClosed: boolean;
    readonly " $fragmentRefs": FragmentRefs<"StoryStatusContainer_story">;
    readonly " $refType": "StoryRowContainer_story";
};
export type StoryRowContainer_story$data = StoryRowContainer_story;
export type StoryRowContainer_story$key = {
    readonly " $data"?: StoryRowContainer_story$data;
    readonly " $fragmentRefs": FragmentRefs<"StoryRowContainer_story">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v1 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
        } as any)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "StoryRowContainer_story",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "StoryMetadata",
                "kind": "LinkedField",
                "name": "metadata",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "author",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publishedAt",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "CommentCounts",
                "kind": "LinkedField",
                "name": "commentCounts",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalPublished",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ModerationQueues",
                "kind": "LinkedField",
                "name": "moderationQueues",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ModerationQueue",
                        "kind": "LinkedField",
                        "name": "reported",
                        "plural": false,
                        "selections": (v1 /*: any*/),
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ModerationQueue",
                        "kind": "LinkedField",
                        "name": "pending",
                        "plural": false,
                        "selections": (v1 /*: any*/),
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCount",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    },
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canModerate",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isClosed",
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StoryStatusContainer_story"
            }
        ],
        "type": "Story",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'a889f7666e825beb111916446a984196';
export default node;
