/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NavigationContainerLocal = {
    readonly dsaFeaturesEnabled: boolean | null;
    readonly " $refType": "NavigationContainerLocal";
};
export type NavigationContainerLocal$data = NavigationContainerLocal;
export type NavigationContainerLocal$key = {
    readonly " $data"?: NavigationContainerLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"NavigationContainerLocal">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationContainerLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dsaFeaturesEnabled",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = 'c9e253aff895b548fa51d2bb271b25da';
export default node;
