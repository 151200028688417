/* tslint:disable */
/* eslint-disable */
/* @relayHash e661d2a1e4b46b514589dea76f330b04 */

import { ConcreteRequest } from "relay-runtime";
export type NEW_USER_MODERATION = "BAN" | "PREMOD" | "%future added value";
export type CreateEmailDomainInput = {
    clientMutationId: string;
    domain: string;
    newUserModeration: NEW_USER_MODERATION;
};
export type CreateEmailDomainMutationVariables = {
    input: CreateEmailDomainInput;
};
export type CreateEmailDomainMutationResponse = {
    readonly createEmailDomain: {
        readonly settings: {
            readonly emailDomainModeration: ReadonlyArray<{
                readonly id: string;
                readonly domain: string;
                readonly newUserModeration: NEW_USER_MODERATION;
            }>;
        };
        readonly clientMutationId: string;
    };
};
export type CreateEmailDomainMutation = {
    readonly response: CreateEmailDomainMutationResponse;
    readonly variables: CreateEmailDomainMutationVariables;
};



/*
mutation CreateEmailDomainMutation(
  $input: CreateEmailDomainInput!
) {
  createEmailDomain(input: $input) {
    settings {
      emailDomainModeration {
        id
        domain
        newUserModeration
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "concreteType": "EmailDomain",
        "kind": "LinkedField",
        "name": "emailDomainModeration",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domain",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newUserModeration",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateEmailDomainMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateEmailDomainPayload",
                    "kind": "LinkedField",
                    "name": "createEmailDomain",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateEmailDomainMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateEmailDomainPayload",
                    "kind": "LinkedField",
                    "name": "createEmailDomain",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "e661d2a1e4b46b514589dea76f330b04",
            "metadata": {},
            "name": "CreateEmailDomainMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = '59e6021f4b3a7a8c7ac89347e38fb40d';
export default node;
