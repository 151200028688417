/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type InviteCompleteFormContainer_settings = {
    readonly organization: {
        readonly name: string;
    };
    readonly " $refType": "InviteCompleteFormContainer_settings";
};
export type InviteCompleteFormContainer_settings$data = InviteCompleteFormContainer_settings;
export type InviteCompleteFormContainer_settings$key = {
    readonly " $data"?: InviteCompleteFormContainer_settings$data;
    readonly " $fragmentRefs": FragmentRefs<"InviteCompleteFormContainer_settings">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteCompleteFormContainer_settings",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Settings",
    "abstractKey": null
} as any);
(node as any).hash = 'd72f26616c149132a394c66e462a1420';
export default node;
