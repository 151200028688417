"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePermissionsAction = void 0;
const predicates_1 = require("./predicates");
const rules_1 = require("./rules");
exports.validatePermissionsAction = (action) => {
    for (const predicate of predicates_1.predicates) {
        const { pass } = predicate(action);
        if (!pass) {
            return false;
        }
    }
    for (const rule of rules_1.validationRules) {
        const { applies } = rule(action);
        if (applies) {
            return true;
        }
    }
    return false;
};
