/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DSAReportStatus = "AWAITING_REVIEW" | "COMPLETED" | "UNDER_REVIEW" | "VOID" | "%future added value";
export type ReportRowContainer_dsaReport = {
    readonly id: string;
    readonly createdAt: string;
    readonly referenceID: string;
    readonly status: DSAReportStatus | null;
    readonly reporter: {
        readonly username: string | null;
    } | null;
    readonly comment: {
        readonly author: {
            readonly username: string | null;
        } | null;
    } | null;
    readonly lawBrokenDescription: string;
    readonly lastUpdated: string | null;
    readonly " $refType": "ReportRowContainer_dsaReport";
};
export type ReportRowContainer_dsaReport$data = ReportRowContainer_dsaReport;
export type ReportRowContainer_dsaReport$key = {
    readonly " $data"?: ReportRowContainer_dsaReport$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportRowContainer_dsaReport">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
        } as any)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ReportRowContainer_dsaReport",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referenceID",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "reporter",
                "plural": false,
                "selections": (v0 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "comment",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": (v0 /*: any*/),
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lawBrokenDescription",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastUpdated",
                "storageKey": null
            }
        ],
        "type": "DSAReport",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '33af62a5e6f0f14676c1f595334627b5';
export default node;
