/* tslint:disable */
/* eslint-disable */
/* @relayHash b06cd5ed69d51a8e9ede9a5f534c5587 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteFlairBadgeInput = {
    clientMutationId: string;
    name: string;
};
export type DeleteFlairBadgeMutationVariables = {
    input: DeleteFlairBadgeInput;
};
export type DeleteFlairBadgeMutationResponse = {
    readonly deleteFlairBadge: {
        readonly settings: {
            readonly flairBadges: {
                readonly flairBadgesEnabled: boolean | null;
                readonly badges: ReadonlyArray<{
                    readonly name: string;
                    readonly url: string;
                }>;
            } | null;
        };
        readonly clientMutationId: string;
    };
};
export type DeleteFlairBadgeMutation = {
    readonly response: DeleteFlairBadgeMutationResponse;
    readonly variables: DeleteFlairBadgeMutationVariables;
};



/*
mutation DeleteFlairBadgeMutation(
  $input: DeleteFlairBadgeInput!
) {
  deleteFlairBadge(input: $input) {
    settings {
      flairBadges {
        flairBadgesEnabled
        badges {
          name
          url
        }
      }
      id
    }
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "concreteType": "FlairBadgeConfiguration",
        "kind": "LinkedField",
        "name": "flairBadges",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "flairBadgesEnabled",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "FlairBadge",
                "kind": "LinkedField",
                "name": "badges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
    } as any);
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteFlairBadgeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteFlairBadgePayload",
                    "kind": "LinkedField",
                    "name": "deleteFlairBadge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteFlairBadgeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteFlairBadgePayload",
                    "kind": "LinkedField",
                    "name": "deleteFlairBadge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Settings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "b06cd5ed69d51a8e9ede9a5f534c5587",
            "metadata": {},
            "name": "DeleteFlairBadgeMutation",
            "operationKind": "mutation",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'a6132d991f28902b4c64364a191f456c';
export default node;
